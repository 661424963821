$starFieldWidth: 2560 * 4
$starFieldHeight: 2560 * 2
$starStartOffset: 600px

$starOneScrollDuration: 100s
$starTwoScrollDuration: 125s
$starThreeScrollDuration: 175s
$numStarOneStars: 1700 * 2
$numStarTwoStars: 700 * 2
$numStarThreeStars: 200 * 2
$numShootingStars: 10
$width: 100%


.sky-container
  display: block
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: linear-gradient(to bottom, #000000 0%, #1e0202 100%)
  //overflow: hidden
  opacity: 1
  transition: all 0.3s
  z-index: 1

  .sky
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

  &.backLayer
    opacity: 0
    display: none
    overflow: hidden
    visibility: hidden
    z-index: 0

  .content
    color: #FFF
    position: absolute
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    margin: -10px -75px 0 0
    font-size: 20px
    font-family: sans-serif
    font-weight: bold

@function create-stars($n)

  $stars: "#{random($starFieldWidth)}px #{random($starFieldHeight)}px #FFF"

  @for $i from 2 through $n
    $stars: "#{$stars} , #{random($starFieldWidth)}px #{random($starFieldHeight)}px #FFF"

  @return unquote($stars)

@mixin star-template($numStars, $starSize, $scrollSpeed)
  z-index: 10
  width: $starSize
  height: $starSize
  border-radius: 50%
  background: transparent
  box-shadow: create-stars($numStars)
  animation: animStar $scrollSpeed linear infinite
  &:after
    content: " "
    top: -$starStartOffset
    width: $starSize
    height: $starSize
    border-radius: 50%
    position: absolute
    background: transparent
    box-shadow: create-stars($numStars)


@mixin shooting-star-template($numStars, $starSize, $speed)
  width: $starSize
  height: $starSize + 80px
  border-top-left-radius: 50%
  border-top-right-radius: 50%
  position: absolute
  bottom: 20px
  right: 20px
  background: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))
  animation: animShootingStar $speed linear infinite

  .shooting-stars-airplane
    background: url("../../assets/images/airplane.png")
    width: 50px
    height: 50px
    display: inline-block
    background-size: contain
    background-repeat: no-repeat
    background-position: bottom
    position: absolute
    left: -23px
    top: -45px


.stars
  @include star-template($numStarOneStars, 1px, $starOneScrollDuration)
.stars1
  @include star-template($numStarTwoStars, 2px, $starTwoScrollDuration)
.stars2
  @include star-template($numStarThreeStars, 3px, $starThreeScrollDuration)
.shooting-stars
  @include shooting-star-template($numShootingStars, 5px, 10s)

@keyframes animStar
  from
    transform: translateY(0px)
  to
    transform: translateY(-#{$starFieldHeight}px) translateX(-#{$starFieldWidth}px)


@keyframes animShootingStar
  from
    transform: translateY(0px) translateX(0px) rotate(-45deg)
    opacity: 1
    height: 5px
  to
    transform: translateY(-#{$starFieldHeight / 2}px) translateX(-#{$starFieldWidth / 4}px) rotate(-45deg)
    opacity: 1
    height: 800px

