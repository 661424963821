@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot?0000');
  src: url('../../fonts/fontello.eot?0000#iefix') format('embedded-opentype'),
  url('../../fonts/fontello.woff2?0000') format('woff2'),
  url('../../fonts/fontello.woff?0000') format('woff'),
  url('../../fonts/fontello.ttf?0000') format('truetype'),
  url('../../fonts/fontello.svg?0000#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  src: url('../../fonts/Mont-Light.eot');
  src: url('../../fonts/Mont-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Mont-Light.woff2') format('woff2'),
  url('../../fonts/Mont-Light.woff') format('woff'),
  url('../../fonts/Mont-Light.ttf') format('truetype'),
  url('../../fonts/Mont-Light.svg#Mont-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Mont';
  src: url('../../fonts/Mont-Regular.eot');
  src: url('../../fonts/Mont-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Mont-Regular.woff2') format('woff2'),
  url('../../fonts/Mont-Regular.woff') format('woff'),
  url('../../fonts/Mont-Regular.ttf') format('truetype'),
  url('../../fonts/Mont-Regular.svg#Mont-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Mont';
  src: url('../../fonts/Mont-SemiBold.eot');
  src: url('../../fonts/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Mont-SemiBold.woff2') format('woff2'),
  url('../../fonts/Mont-SemiBold.woff') format('woff'),
  url('../../fonts/Mont-SemiBold.ttf') format('truetype'),
  url('../../fonts/Mont-SemiBold.svg#Mont-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Mont';
  src: url('../../fonts/Mont-Bold.eot');
  src: url('../../fonts/Mont-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Mont-Bold.woff2') format('woff2'),
  url('../../fonts/Mont-Bold.woff') format('woff'),
  url('../../fonts/Mont-Bold.ttf') format('truetype'),
  url('../../fonts/Mont-Bold.svg#Mont-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Mont';
  src: url('../../fonts/Mont-Heavy.eot');
  src: url('../../fonts/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Mont-Heavy.woff2') format('woff2'),
  url('../../fonts/Mont-Heavy.woff') format('woff'),
  url('../../fonts/Mont-Heavy.ttf') format('truetype'),
  url('../../fonts/Mont-Heavy.svg#Mont-Heavy') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Mont';
  src: url('../../fonts/Mont-Black.eot');
  src: url('../../fonts/Mont-Black.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Mont-Black.woff2') format('woff2'),
  url('../../fonts/Mont-Black.woff') format('woff'),
  url('../../fonts/Mont-Black.ttf') format('truetype'),
  url('../../fonts/Mont-Black.svg#Mont-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Allan';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/allan-regular.eot');
  src: local(''),
  url('../../fonts/allan-regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/allan-regular.woff2') format('woff2'),
  url('../../fonts/allan-regular.woff') format('woff'),
  url('../../fonts/allan-regular.ttf') format('truetype'),
  url('../../fonts/allan-regular.svg#Allan') format('svg');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/jost-v14-latin-regular.eot');
  src: local(''),
  url('../../fonts/jost-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/jost-v14-latin-regular.woff2') format('woff2'),
  url('../../fonts/jost-v14-latin-regular.woff') format('woff'),
  url('../../fonts/jost-v14-latin-regular.ttf') format('truetype'),
  url('../../fonts/jost-v14-latin-regular.svg#Jost') format('svg');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/jost-v14-latin-600.eot'); 
  src: local(''),
  url('../../fonts/jost-v14-latin-600.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/jost-v14-latin-600.woff2') format('woff2'),
  url('../../fonts/jost-v14-latin-600.woff') format('woff'),
  url('../../fonts/jost-v14-latin-600.ttf') format('truetype'),
  url('../../fonts/jost-v14-latin-600.svg#Jost') format('svg');
}