.popup-holder{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999 !important;

    &.fullscreen {
        .error_modal {
            &.connection_modal{
                height: 246px;

                .modal_body{
                    gap: 22px;
                }
            }
        }
    }

    .error_modal{
        position: absolute;
        width: 482px;
        min-height: 176px;
        background: #FFFFFF;
        border-radius: 16px;
        display: flex;
        padding: 15px;
        font-family: 'Mont', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        color: #000000;

        .modal_body{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100%;
            gap: 44px;

            .modal_text{
                width: 100%;
                max-width: 293px;
                height: 30px;
            }

            .ok_btn{
                border: none;
                background-color: transparent;
                font-family: 'Mont';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                cursor: pointer;
            }

            .hide {
                display: none;
            }
        }

        .contentImg {
            width: 86px;
            height: 86px;
        }

        .modal_title{
            width: 100%;
            font-family: 'Mont', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            color: #000000;

            .title_text{
                text-align: center;
            }
        }

        .error_ico{
            position: relative;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 600px) {
    .error_modal {
        width: 80% !important;
    } 
}
