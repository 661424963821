@import "fonts";
@import "variables";
@import "common";

//Home Styles -- Start

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behaviour: smooth;
}

body {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: linear-gradient(to bottom, #000000 0%, #1e0202 100%)
}

#root {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

#root:has(.fullscreen) {
  pointer-events: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  background: #C4C4C4;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4;
  border-radius: 40px;
}

.panda-mr-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(var(--main-color), 1);
  transition: background-color 240ms;
  overflow: hidden;
}

.unity-board > * {
  z-index: 1;
}

.panda-mr-header {
  position: sticky;
  width: 100%;
  height: 10rem;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: var(--main-color);
}

.games-container {
  width: 90vw;
  height: 90vh;
  transform: translate(5vw, 5vh);
  position: absolute;
  z-index: 100;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 43px 34px;

  .book_container{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 20px;
    overflow-y: auto;
  }

  .games-iframe {
    width: 100%;
    height: 100%;
    background-color: black;
    border: none;
    border-radius: 20px;
    overflow-y: auto;
  
    img {
      object-fit: cover;
      width: 100%;
      transform-origin: 0% 0% 0px;
    }
  }

  .pdf_download {
    position: absolute;
    top: 10px;
    left: 34px;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 116px;
    height: 24px;
    mask: url("../../images/pdf-download.png") no-repeat;
    mask-size: contain;
    background-color: white;
    cursor: pointer;
  }

  .zoom_container{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 96%;
    height: 3rem;
    gap: 24px;

    .zoom_in {
      display: inline-block;
      text-decoration: none;
      width: 3rem;
      height: 3rem;
      mask: url("../../images/zoom_in.png") no-repeat;
      mask-size: contain;
      background-color: white;
      cursor: pointer;
    }
  
    .zoom_out {
      display: inline-block;
      text-decoration: none;
      width: 3rem;
      height: 3rem;
      mask: url("../../images/zoom_out.png") no-repeat;
      mask-size: contain;
      background-color: white;
      cursor: pointer;
    }
  }

  

  &.shop {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    width: 80vw;
    height: 75vh;
    transform: translate(10vw, 14vh);

    .icon-close {
      background-color: black;
      top: 1.5rem;
      right: 2.2rem;
      width: 3rem;
      height: 3rem;
    }
  }

  .icon-close {
    background-color: white;
  }
}

.games-iframe {
  width: 100%;
  height: 100%;
  background-color: black;
  border: none;
  border-radius: 20px;
  overflow-y: auto;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.container {
  width: 100%;
  max-width: 122rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.panda-mr-header-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo-block {
  width: 8rem;
  height: 6.4rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    display: block;
    filter: var(--image-invert);
  }
}

.panda-mr-background {
  width: 100vw;
  min-height: 100vh;
  z-index: 9;
  background-size: 126vw;
  background-position: 50% 13vh;
  background-repeat: no-repeat;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}

.panda-mr-background-layer {
  background: linear-gradient(180deg, #000000 0%, #000000 78.65%);
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}

.header-content-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.menu-item {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 3rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2rem;
  color: rgb(var(--revert-color));
  letter-spacing: 0.2em;
}

.header-content-left {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
}

.header-content-left-inner {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 100%;
}

.header-content-right {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 100%;
}

.login-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: auto;
  min-width: 10.4rem;
  height: 4.8rem;
  border-radius: 5.6rem;
  background-color: var(--main-color);
  border: 1px solid rgb(var(--revert-color));
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
  margin-left: 4rem;
  color: rgb(var(--revert-color));
  cursor: pointer;
}

.mode-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 4.6rem;
  cursor: pointer;

  i {
    font-size: 1.8rem;
    line-height: 2rem;
    color: rgb(var(--revert-color));
  }
}

.panda-mr-main-wrapper {
  width: 100%;
  height: auto;
  background-color: rgba(var(--main-color));
  transition: background-color 240ms;
}

.panda-world-content {
  width: 100%;
  height: auto;
  padding-top: 6.8rem;
}

.panda-world-info {
  width: 100%;
  max-width: 75rem;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

.get-started {
  width: auto;
  height: 5.8rem;
  padding: 0 2.4rem;
  margin-top: 3.8rem;
  background-color: rgba(229, 249, 111, 1);
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 800;
  text-transform: capitalize;
  border-radius: 2.8rem;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
}

.panda-world-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 3.2rem;
  line-height: 7.8rem;
  font-weight: 600;
  color: rgb(var(--revert-color));
}

.panda-world-text {
  text-align: center;
  text-transform: uppercase;
  font-size: 6.4rem;
  line-height: 6.2rem;
  font-weight: 900;
  color: rgb(var(--revert-color));
}

.panda-world-img {
  position: relative;
  width: 100%;
  height: 48rem;
  overflow: hidden;

  img {
    position: absolute;
    left: 6rem;
    top: 0;
    height: 100%;
  }
}

.meta-gallery-wrap {
  width: 100%;
  height: auto;
  padding-top: 11.2rem;
}

.meta-gallery-info {
  width: 100%;
  max-width: 79.6rem;
  height: auto;
  margin: 0 auto;
}

.meta-gallery-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 4.8rem;
  line-height: 4.8rem;
  font-weight: 600;
  color: rgb(var(--revert-color));
}

.meta-gallery-text {
  text-align: center;
  font-size: 2.4rem;
  line-height: 2.6rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  padding: 0 5rem;
  margin-top: 2.4rem;
}

.meta-gallery-content {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto 10fr;
  grid-row-gap: 2rem;
  padding-top: 6rem;
}

.meta-gallery-content-top {
  width: 100%;
  height: 27.8rem;
  border-radius: 1.2rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.meta-gallery-content-bottom {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
}

.meta-gallery-content-bottom-inner {
  width: 100%;
  height: 51.8rem;
  border-radius: 1.2rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.metacom-wrap {
  width: 100%;
  height: auto;
  padding-top: 16.4rem;
}

.metacom-info {
  width: 100%;
  height: auto;
  max-width: 54.8rem;
  margin: 0 auto;
  text-align: center;
}

.metacom-info-title {
  text-transform: uppercase;
  font-size: 8rem;
  line-height: 6.4rem;
  font-weight: 800;
  color: rgb(var(--revert-color));
}

.metacom-info-text {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.67);
  padding-top: 3.4rem;
}

.metacom-images-wrap {
  position: relative;
  width: 100%;
  height: 86rem;
}

.meta-images-1 {
  width: 68rem;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-140%);
}

.meta-images-2 {
  width: 120rem;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-65%);
}

.meta-images-3 {
  position: absolute;
  width: 83rem;
  height: auto;
  left: 50%;
  top: 0;
  transform: translateX(34%);
}

.meta-images-4 {
  position: absolute;
  width: 64rem;
  height: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(43%);
}

.happening-wrap {
  width: 100%;
  height: auto;
}

.happening-wrap-title {
  text-transform: uppercase;
  max-width: 82rem;
  font-size: 14rem;
  line-height: 10.7rem;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

.happening-wrap-text {
  font-size: 3.4rem;
  padding-top: 2.7rem;
  line-height: 2.5rem;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}

.months-wrap {
  width: 100%;
  height: 28rem;
  position: relative;
  margin-top: 3.4rem;
}

.months-wrap-scroll {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.months-content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 38.4rem;
  height: 100%;
}

.months-content-inner {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 3.6rem;
  border: 1px solid #E9E9E9;
  border-radius: 0.8rem;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }
}

.months-content-inner-top {
  width: 100%;
  height: 12.6rem;
  flex: 0 0 auto;
  padding: 1.8rem 2.2rem;

  &.blue {
    background-color: rgba(24, 104, 251, 1);
  }

  &.pink {
    background-color: rgba(240, 71, 193, 1);
  }

  &.yellow {
    background-color: rgba(253, 203, 74, 1);
  }

  &.green {
    background-color: rgba(100, 184, 87, 1);
  }
}

.months-content-inner-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem;
}

.months-content-inner-b-info-top {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.months-content-inner-b-info-top-title {
  text-transform: capitalize;
  font-size: 1.8rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

.room-info {
  font-size: 1.8rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

.months-content-inner-b-info-top-right {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: rgba(57, 205, 33, 1);
  padding-top: 0.2rem;
  font-weight: 600;

  &:before {
    position: absolute;
    content: "";
    width: 0.7rem;
    height: 0.7rem;
    top: 0.55rem;
    left: -1rem;
    border-radius: 50%;
    background-color: rgb(57, 205, 33);
  }
}

.months-content-inner-b-info-bottom {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.months-content-inner-top-info {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.6rem;
  padding-left: 0.5rem;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
}

.months-content-inner-b-info-bottom-left {
  display: flex;
  flex-direction: column;
}

.months-content-inner-top-date {
  font-size: 8.7rem;
  line-height: 8.7rem;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
}

.meta-world-wrap {
  width: 100%;
  height: auto;
  margin-top: 21rem;
}

.meta-world-content {
  position: relative;
  width: 100%;
  height: 65rem;
  background-image: url("../../images/about.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 3.2rem;
}

.meta-world-info-title {
  position: absolute;
  left: 5.6rem;
  bottom: 4.4rem;
  text-transform: uppercase;
  max-width: 51.2rem;
  font-size: 13.8rem;
  line-height: 11rem;
  color: var(--main-color);
  font-weight: 800;
}

.decide-wrap {
  width: 100%;
  height: auto;
  margin-top: 18rem;
}

.decide-title {
  text-transform: uppercase;
  font-size: 7rem;
  line-height: 7rem;
  color: rgb(var(--revert-color));
  max-width: 65rem;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
}

.decide-image-wrap {
  position: relative;
  width: 100%;
  height: 52rem;
  margin-top: 8rem;
  background-color: var(--main-color);
}

.big-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 46rem;
  height: 46rem;
  border-radius: 50%;
  padding: 5rem;

  &:after {
    content: "";
    position: absolute;
    top: 5rem;
    left: 5rem;
    width: calc(100% - 10rem);
    height: calc(100% - 10rem);
    background-color: rgb(var(--revert-color));
    border-radius: 50%;
    opacity: 0.2;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5rem solid rgb(var(--revert-color));
    opacity: 0.1;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
    position: relative;
  }
}

.small-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 12rem;
  right: 0;
  margin: 0 auto;
  padding: 3rem;
  width: 27.4rem;
  height: 27.4rem;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    top: 3rem;
    left: 3rem;
    width: calc(100% - 6rem);
    height: calc(100% - 6rem);
    background-color: rgb(var(--revert-color));
    border-radius: 50%;
    opacity: 0.2;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3rem solid rgb(var(--revert-color));
    opacity: 0.1;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
    position: relative;
  }
}

.middle-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4rem;
  right: 0;
  padding: 4rem;
  width: 35.2rem;
  height: 35.2rem;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    top: 4rem;
    left: 4rem;
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
    background-color: rgb(var(--revert-color));
    border-radius: 50%;
    opacity: 0.2;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4rem solid rgb(var(--revert-color));
    opacity: 0.1;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
    position: relative;
  }
}

.meta-partners-wrap {
  width: 100%;
  height: auto;
  margin-top: 16rem;
}

.meta-partners-title {
  text-transform: uppercase;
  text-align: center;
  font-size: 7rem;
  line-height: 7.8rem;
  color: rgb(var(--revert-color));
  font-weight: 800;
}

.meta-partners-text {
  text-align: center;
  padding-top: 2.4rem;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 2.8rem;
  color: rgba(0, 0, 0, 0.5);
}

.meta-partners-content {
  width: 100%;
  margin-top: 6.2rem;
  background-color: rgb(var(--revert-color));
}

.meta-partners-content-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 6rem 2.8rem;
}

.meta-partners-content-inner {
  flex: 0 0 auto;
  height: auto;
  margin-right: 10rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }
}

.testimonials-wrap {
  width: 100%;
  padding: 10rem 0;
  background-color: var(--main-color);
}

.testimonials-content {
  position: relative;
  width: 100%;
  max-width: 87rem;
  min-height: 62rem;
  margin: 0 auto;
  padding: 0 10rem;
  background-image: url("../../images/testamonials-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 2.8rem;

  &:before {
    position: absolute;
    content: '';
    bottom: 1rem;
    left: -16.8rem;
    width: 37.4rem;
    height: 31rem;
    background-image: url("../../images/microphone.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    z-index: 1;
  }
}

.testimonials-title {
  text-align: center;
  padding-top: 6rem;
  font-size: 2rem;
  line-height: 7.8rem;
  font-weight: 800;
  color: rgb(var(--revert-color));
}

.access-platforms-wrap {
  width: 100%;
  height: 23.6rem;
  background-color: rgb(var(--revert-color));
}

.access-platforms-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 9.4rem;
}

.access-platforms-content-inner {
  flex: 0 0 auto;
  margin-right: 10rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }
}

.coming-soon-wrap {
  position: relative;
  width: 100%;
  min-height: 73.5rem;
  padding: 5rem 0;
  //background-image: url("../../images/coming-soon-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--main-color);
    opacity: 0.67;
  }
}

.coming-soon-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coming-soon-title {
  position: relative;
  padding-top: 15rem;
  text-transform: uppercase;
  font-size: 4rem;
  line-height: 7.4rem;
  color: rgb(var(--revert-color));
  font-weight: 900;

  &:before {
    position: absolute;
    content: '';
    width: 14.4rem;
    height: 12.4rem;
    left: -8rem;
    bottom: -1.8rem;
    background-image: url("../../images/flash.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    z-index: 1;
  }
}

.coming-soon-text {
  margin-top: 3.6rem;
  padding: 0 16rem;
  text-align: center;
  font-size: 5.2rem;
  line-height: 5.2rem;
  color: rgb(var(--revert-color));
  font-weight: 800;
}

.coming-soon-info-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3.6rem;
}

.coming-soon-info-inner {
  flex: 0 0 auto;
  padding: 0 1.2rem;
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 400;

  &:last-child {
    border: unset;
  }
}

//Home Styles -- End


//Help Styles -- Start

.root-page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0000008c;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 111;

  &.backLayer {
    z-index: 0;
    display: none;
  }
}

.help-step-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;

  &.active {
    display: flex;
  }

  .help-step-illustration {
    width: 55.625%;
    display: flex;
    padding-right: 8.2rem;
    box-sizing: border-box;

    img {
      display: block;
    }
  }

  .help-step-info {
    width: 44.375%;
  }

  .help-step-info-inner {
    width: 65.101%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  &[data-step="1"] {
    .help-step-illustration {
      justify-content: flex-start;
      align-items: flex-end;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left bottom;
      }
    }
  }

  &[data-step="2"] {
    .help-step-illustration {
      justify-content: center;
      align-items: center;

      img {
        width: 65.543%;
        height: auto;
      }
    }
  }

  &[data-step="3"] {
    .help-step-illustration {
      justify-content: flex-start;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}

.cover-page-logo {
  width: auto;
  height: 8.9rem;
  display: block;
  margin-bottom: 10.7rem;
  //filter: var(--image-invert);
}

.help-step-title {
  width: 100%;
  padding-bottom: 2.7rem;
  font-size: 3.2rem;
  line-height: 4.1rem;
  font-weight: 700;
  color: rgb(var(--revert-color));
}

.help-step-description {
  width: 100%;
  min-height: 7.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 4.6rem;
  font-size: 2rem;
  line-height: 2.6rem;
  color: rgb(var(--revert-color));
}

.help-step-next-btn {
  min-width: 16rem;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-right: 4.7rem;
  padding-left: 1.6rem;
  box-sizing: border-box;
  background-color: #E5F96F;
  position: relative;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  color: #000000;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  text-transform: capitalize;

  i {
    position: absolute;
    top: 50%;
    right: 1.7rem;
    font-size: 1.3rem;
    transform: translateY(-50%);
    color: #000000;
  }
}

.root-skip-btn {
  position: absolute;
  z-index: 1;
  top: 12rem;
  right: 12rem;
  text-transform: capitalize;
  color: rgb(var(--revert-color));
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  background-color: transparent;
  border: none;
  outline: none;
}

@media screen and (orientation: portrait) {
  .help-step-wrapper {
    .help-step-illustration {
      display: none;
    }

    .help-step-info-inner {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding-top: 20rem;
    }

    .help-step-title {
      text-align: center;
      font-size: 4.2rem;
      line-height: 5.4rem;
    }

    .help-step-description {
      text-align: center;
      font-size: 3rem;
      line-height: 4.6rem;
    }

    .cover-page-logo {
      height: 14rem;
    }

    .help-step-info {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .root-skip-btn {
    top: 6rem;
    right: 6rem;
    font-size: 2.6rem;
  }

  .help-step-next-btn {
    min-width: 25rem;
    height: 8rem;
    position: fixed;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 2.6rem;
    font-size: 2.6rem;

    i {
      right: 2.7rem;
      font-size: 3rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .help-step-wrapper {
    .help-step-illustration {
      display: none;
    }

    .help-step-info-inner {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding-top: 20rem;
    }

    .help-step-title {
      text-align: center;
      font-size: 4.2rem;
      line-height: 5.4rem;
    }

    .help-step-description {
      text-align: center;
      font-size: 3rem;
      line-height: 4.6rem;
    }

    .cover-page-logo {
      height: 14rem;
    }

    .help-step-info {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .help-step-info-inner {
      padding-top: 14rem;
    }

    .cover-page-logo {
      height: 6.3rem;
    }

    .help-step-title {
      text-align: center;
      font-size: 2.2rem;
      line-height: 2.5rem;
    }

    .help-step-description {
      text-align: center;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .help-step-next-btn {
      min-width: 16rem;
      height: 5rem;
      bottom: 4rem;
      font-size: 1.6rem;

      i {
        right: 1.7rem;
        font-size: 2rem;
      }
    }

    .cover-page-logo {
      margin-bottom: 7.7rem;
    }
  }

  .root-skip-btn {
    top: 6rem;
    right: 6rem;
    font-size: 2.6rem;
  }

  .help-step-next-btn {
    min-width: 25rem;
    height: 8rem;
    position: fixed;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 2.6rem;
    font-size: 2.6rem;

    i {
      right: 2.7rem;
      font-size: 3rem;
    }
  }

  .root-page-wrapper {
    .root-skip-btn {
      top: 3.8rem;
      right: 3.8rem;
      font-size: 1.6rem;
    }
  }
}

@media screen and (min-aspect-ratio: 329/464) and (max-width: 375px) {
  .help-step-wrapper {
    .help-step-info-inner {
      padding-top: 7rem;
    }

    .cover-page-logo {
      margin-bottom: 3.7rem;
    }
  }
}

//Help Styles -- End


//Root Page == Start

.root-previous-step {
  position: absolute;
  top: 12.1rem;
  left: 12.2rem;
  font-size: 2rem;
  color: rgb(var(--revert-color));
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 1;
}

.root-close-button {
  position: absolute;
  top: 12.2rem;
  right: 12.2rem;
  font-size: 2rem;
  color: rgb(var(--revert-color));
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 1;
}

.root-page-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: 'header' 'body' 'footer';
  background-color: rgba(var(--main-color), 1);
  overflow: hidden;
  z-index: 1;

  &-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(var(--main-color), 1);

    .root-page-logo {
      position: static;
      transform: translateX(0);
      margin-bottom: 9.9rem;
    }
  }
}


.root-page-header {
  width: 100%;
  height: 15rem;
  grid-area: header;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.root-page-illustration {
  width: 52.639%;
  height: 100%;
  padding-right: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > img {
    width: 100%;
  }

  & + .root-page-body-wrapper {
    width: 47.361%;
    position: static;
    display: block;

    .root-page-body {
      align-items: flex-start;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.root-page-body-wrapper {
  width: 100vw;
  height: 100%;
  grid-area: body;
}

.root-page-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 12.2rem 12rem 10rem;
  box-sizing: border-box;
}

.root-page-slider {
  overflow: hidden;
  position: relative;
  height: 70vh;
}

.inner-slider {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.slider-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 80%;

  input {
    background-color: transparent !important;
    background-image: none !important;
  }
}

.root-page-logo {
  width: auto;
  height: 9.6rem;
  display: block;
  //position: absolute;
  //left: 50%;
  //bottom: 1rem;
  //transform: translateX(-50%);
}

.root-page-info {
  width: 100%;
  //margin-bottom: 6rem;
}

.root-page-title {
  text-align: center;
  color: rgb(var(--revert-color));
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1.7rem;
  }
}

.root-page-check {
  width: 100%;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 400;
  color: rgb(var(--revert-color));

  span {
    font-weight: 900
  }
}

.root-page-error-message {
  font-size: 1.5rem;
  color: red;
  text-align: center;

  &.form_field {
    position: absolute;
    bottom: 15px;
    font-size: 12px;
  }

  &.first_elem {
    top: 0;
  }
}

.nft_name_value {
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .nft_name_size {
    font-size: 16px;

    &.error {
      color: #EB4242;
    }
  }
}

.root-page-error-message-code {
  font-size: 1.5rem;
  color: red;
  text-align: center;
  margin-top: 3rem;
}

.root-page-description {
  //width: 100%;
  //max-width: 88.1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 2.8rem;
  line-height: 3.2rem;
  color: white;
  font-weight: 300;

  &.help-text {
    font-size: 2.2rem;
  }
}

.root-body-input {
  width: 100%;
  height: 7.2rem;
  font-size: 6.2rem;
  text-align: center;
  color: rgba(var(--mirror-color), 1);
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 800;

  &::placeholder {
    color: rgba(var(--mirror-color), 0.2);
  }
}

.root-password-field {
  width: 100%;
  //height: 16rem;
}

.root-password-field-inner {
  width: 100%;
  height: 16rem;
  max-width: 77.8rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &.full {
    .root-password-blocks {
      display: none;
    }
  }

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    outline: none;
    color: transparent;
    font-size: 8.9rem;
    letter-spacing: 12.4rem;
    padding-left: 5rem;
    caret-color: white;

    &::selection {
      background-color: rgba(255, 255, 255, 0.1) !important;
      -webkit-text-fill-color: white;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=text] {
      -moz-appearance: textfield;
    }
  }
}

.root-password-blocks {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  span {
    width: 13rem;
    height: 100%;
    display: flex;
    border-radius: 1.6rem;
    background-color: white;
    background-color: rgba(var(--main-color), 0.12);
    font-size: 8.9rem;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
  }
}

.root-page-password-field {
  width: 100%;
  height: 7.2rem;
  padding-left: 12rem;
  padding-right: 12rem;
  display: flex;

  .root-body-pass-field {
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: rgb(var(--revert-color));
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 1rem;
    font-size: 4.7rem;
    padding-right: 9.4rem;
    padding-left: 9.4rem;
    box-sizing: border-box;

    &::placeholder {
      font-family: var(--panda-font);
      letter-spacing: normal;
      color: rgba(135, 135, 135, 0.25);
      font-weight: 700;
      font-size: 6.2rem;
    }
  }

  .root-page-pass-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .root-page-pass-action {
    padding: 0;
    border-radius: 50%;
    margin-left: 1.6rem;
    border: none;
    outline: none;
  }
}

.root-error-wrapper {
  width: 100%;
  text-align: center;
  margin-top: -12rem;
}

.root-error-icon {
  height: 24.2rem;
}

.root-checkbox-wrapper {
  width: 100%;
  max-width: 72.5rem;
  margin-left: auto;
  margin-right: auto;
}

.root-checkbox-row {
  width: 100%;
  min-height: 3.6rem;
  padding-left: 6.6rem;
  position: relative;

  p {
    width: 100%;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: rgb(var(--revert-color));

    span {
      color: #afe489;
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .root-checkbox-imitation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    position: absolute;
    top: 0.5rem;
    left: 0;
    border-radius: 0.8rem;

    &.active {
      background-color: black;
    }

    input {
      display: none;

      &:checked + span {
        position: relative;
        background-image: url("../../icons/check.svg");
        background-position: center;
        background-size: contain;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        filter: invert(1);
        border: none;
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 0.8rem;
      border: 0.1rem solid rgba(var(--mirror-color), 1);
      cursor: pointer;
    }
  }
}

.spiner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  position: absolute;
  z-index: 10;
  top: 0;

  img {
    width: 100px;
    height: 100px;
  }
}

.root-page-footer {
  width: 100%;
  min-height: 9.5rem;
  padding-bottom: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .forgot-btn {
    background: transparent;
    border: none;
    padding: 15px;
  }
}

.root-footer-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .resend-email {
    font-size: 1.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: #ffffff;
    margin-top: 1rem;
  }
}

.root-footer-btn {
  padding-left: 3.3rem;
  padding-right: 3.3rem;
  height: 4.8rem;
  border-radius: 2.4rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid rgba(var(--mirror-color), 1);
  color: rgba(var(--mirror-color), 1);
  background-color: transparent;
  opacity: 0.4;
  font-size: 1.6rem;
  //pointer-events: none;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    cursor: auto;
  }
}

.root-call-to-sign-in {
  text-align: center;
  color: rgba(var(--mirror-color), 1);
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;

  span {
    opacity: 0.5;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

@media screen and (max-width: 979px) {
  .root-page-inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .root-page-illustration {
    display: none;

    & + .root-page-body-wrapper {
      width: 100%;

      .root-authorization-variant-list {
        .root-authorization-variant-li {
          height: 5.8rem;
          padding-left: 2rem;
          padding-right: 2rem;
          font-size: 1.4rem;

          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }

      .root-authorization-or-label {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
      }

      .root-authorization-with-email {
        height: 5.8rem;
        font-size: 1.6rem;
        font-weight: 300;
      }
    }
  }

  .root-close-button {
    position: absolute;
    top: 3.2rem;
    right: 3.2rem;
  }

  .root-page-body {
    padding-top: 8rem;
    align-items: center !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .root-page-logo {
    height: 7rem;
    //margin-bottom: 13.3rem;
  }

  .slider-item {
    height: 100%;
  }

  .root-previous-step {
    top: 3.8rem;
    left: 3.8rem;
  }

  .root-page-info {
    //margin-bottom: 3rem;
  }

  .root-page-title {
    font-size: 2.2rem;
  }
  .root-body-input {
    height: 3.2rem;
    font-size: 2.4rem;
  }

  .root-page-footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .root-footer-btn {
    width: 100%;
    justify-content: center;
  }

  .root-page-description {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }

  .root-page-password-field {
    border-left: none;
    border-right: none;
    height: 3.2rem;
    padding-left: 0;
    padding-right: 0;

    .root-body-pass-field {
      font-size: 2rem;
      padding-left: 0;
      padding-right: 7rem;
      text-align: left;

      &::placeholder {
        font-size: 2.4rem;
      }
    }

    .root-page-pass-action {
      font-size: 2rem;
      margin-left: 1.3rem;
    }
  }

  .root-checkbox-row {
    padding-left: 4.6rem;

    p {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    .root-checkbox-imitation {
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  .root-password-blocks span {
    width: 4rem;
    border-radius: 0.8rem;
  }

  .root-password-field-inner {
    max-width: 23.4rem;
    height: 6rem;

    input {
      width: 110%;
      font-size: 2.9rem;
      letter-spacing: 3.2rem;
      padding-left: 1.1rem;
    }
  }
  .root-page-error-message {
    font-size: 1rem;
  }

  .root-password-field {
    height: 10rem;
    overflow: hidden;
  }
}

//Root Page == End


//Loader -- Start

@keyframes leaves {
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes inAnimation {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outAnimation {
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes op {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.space-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11111111;
  display: flex;
  justify-content: center;
  align-items: center;

  .space-img {
    object-fit: cover;
    position: absolute;
    left: -1%;
    right: -1%;
    width: 102%;
    height: 102%;
    filter: blur(5px);
  }

  .space-spinner {
    position: relative;
    background-image: url("../../loaders/Spin.svg");
    background-size: cover;
    width: 50vw;
    height: 50vw;
    animation: leaves 3s ease-in-out infinite alternate;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .space-description {
    width: 23%;
    color: white;
    font-size: 23px;
    text-align: center;
    position: absolute;
    //animation: op 1s ease-in-out infinite alternate;

  }
}

@keyframes fadeIn {
  0% {
    opacity: .1;
    transform: scale(.1);
  }
  10% {
    opacity: 1;
    transform: scale(0.95);
  }
  15% {
    opacity: 0;
    transform: scale(.1);
  }
}

.space-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
  flex-direction: column;
  color: white;
  width: 35vw;
  height: 35vw;

  .space-spinner {
    position: absolute;
    background-image: url("../../loaders/Spin.svg");
    background-size: cover;
    animation: leaves 3s ease-in-out infinite alternate;
  }

  h1 {
    font-size: 35px;
    text-align: center;
    text-shadow: 6px 4px 9px #000000;
  }

  .space-list {
    font-size: 18px;
    list-style-type: none;
    text-align: center;
    min-height: 6rem;
    width: 100%;
    margin-top: 20px;

    li {
      animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
      animation-iteration-count: infinite;
      animation-name: fadeIn;
      animation-duration: 44s;
      opacity: 0;
      width: 100%;
      position: absolute;
      text-align: center;
      text-shadow: 5px 5px 9px #000000;
      padding: 15px;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 4s
      }

      &:nth-child(3) {
        animation-delay: 8s
      }

      &:nth-child(4) {
        animation-delay: 12s
      }

      &:nth-child(5) {
        animation-delay: 16s
      }

      &:nth-child(6) {
        animation-delay: 20s
      }

      &:nth-child(7) {
        animation-delay: 24s
      }

      &:nth-child(8) {
        animation-delay: 28s
      }

      &:nth-child(9) {
        animation-delay: 32s
      }

      &:nth-child(10) {
        animation-delay: 36s
      }

      &:nth-child(11) {
        animation-delay: 40s
      }
    }
  }
}

.loading-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.char-block {
  width: 16.8rem;
  height: 23.4rem;
  margin-bottom: 1.6rem;
  position: relative;
  background-image: url("../../loaders/qatar_loader.gif");
  background-size: contain;
  background-repeat: no-repeat;
}

.loading-label {
  text-align: center;
  color: rgb(var(--revert-color));
  font-size: 1.6rem;
  font-weight: 700;
}

.card-spiner {
  width: 100%;
  height: 100%;
  max-height: 24rem;
  object-fit: cover;
  opacity: 0.8;
  border-radius: 1.2rem;
}

//Loader -- End
.p-mr-modal-inventory {
  position: fixed;
  width: 58%;
  min-width: 828px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  z-index: 9;
  background-size: 138vw;
  background-position: -16vw 10vw;
  background-repeat: no-repeat;

  &.active {
    display: flex;
  }

  * :not(input) {
    user-select: none;
  }

  button {
    &:active {
      transform: scale(0.96);
    }
  }
}

//EventsModal -- Start
.p-mr-modal-holder {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  //padding: 1.2rem;
  z-index: 9;
  background-size: 138vw;
  background-position: -16vw 17vw;
  background-repeat: no-repeat;

  &.white {
    background-color: white;
  }

  &.ucraft {
    display: flex;
  }

  &.active {
    display: flex;
    flex-direction: column;
  }

  * :not(input) {
    user-select: none;
  }

  button {
    &:active {
      transform: scale(0.96);
    }
  }
}

.left-container {
  width: 40%;
  height: 100vh;

  @media screen and (max-width: 600px) {
    min-width: 50%;
  }
}

.countDown {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .timer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    .numbers {
      color: white;
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      .segment {
        margin: 0 20px;
      }
    }

  }

  .launches {
    display: inline-block;
    color: white;
    font-size: 22px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    padding: 15px;
  }
}

.p-mr-modal-holder {
  &.categores {
    background-size: contain;
    background-position: left;
    background-color: white;
    justify-content: normal;

    .category-container {
      display: flex;
      width: 100%;
      max-width: 82rem;
      flex-flow: wrap;

      .category {
        background-color: white;
        width: 40%;
        max-width: 380px;
        min-width: 300px;
        max-height: 70px;
        border: 1px solid #191919;
        border-radius: 108px;
        text-align: center;
        line-height: 70px;
        cursor: pointer;
      }
    }


    .right-container {
      width: 100%;

      .logo-svg {
        margin-bottom: 51px;
      }

      .title {
        margin-bottom: 11px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        text-shadow: 1px 0px 7px white;
      }

      .description {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 60px;
        text-shadow: 1px 0px 7px white;
      }

      .category {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        margin: 15px;

        a {
          display: inline-block;
          color: black;
          text-decoration: none;
          width: 100%;
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 700px) {
      & {

        .right-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }


    @media screen and (max-width: 600px) {
      & {
        background-position: top;

        .left-container {
          min-width: 0;
        }

        .right-container {
          position: absolute;
          left: 0;
          top: 0;
          padding: 10px;
          background: #ffffffba;
          height: 100vh;
          width: 100%;

        }
      }
    }
  }


}

.user-count {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 13px;
  z-index: 7;
  color: white;
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  padding: 5px 7px;
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  text-shadow: 1px 1px 2px black;

  &.column {
    .user-like-container {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-icon, .user-like {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    margin-right: 3px;
  }

  .user-icon {
    background-image: url("../../images/User.png");
  }

  .user-like {
    background-image: url("../../images/Heart.png");
  }
}

.p-mr-modal-popover {
  position: absolute;
  top: 4rem;
  right: -1.4rem;
  z-index: 9;
  cursor: default;
  //overflow: hidden;
  animation: close 180ms ease-out;
  display: none;

  &.ucraft {
    width: 100%;
    top: 5rem;
    right: 4.2rem;
  }

  &.open {
    display: block;
  }
}

.p-mr-modal-popover-inside {
  position: relative;
  padding: 0.4rem 1.4rem 1.4rem 1.4rem;
}

.p-mr-modal-popover-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1.7rem;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 0.5rem 1.2rem rgba(90, 95, 105, 0.2);
  //overflow: hidden;
}

.p-mr-modal-user-profile {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%;

  &.open {
    display: block;
  }
}

.p-mr-modal-user-profile-inside {
  width: 100%;
  height: 100%;
  max-width: 1093px;
  max-height: 665px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;

  &.center {
    justify-content: center;
  }
}

.p-mr-modal-user-profile-avatar {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45.7rem;
  height: 100%;
  padding: 20px;
  background-color: #efefef;
  border-radius: 1.6rem;

  .avatar-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .spiner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 10%;
    position: absolute;
  }

  .edit_profile {
    position: absolute;
    top: 18px;
    left: 26px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }
}

.p-mr-modal-user-profile-back {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  top: 4rem;
  left: 4rem;
  color: black;

  &:active .icon-back {
    transform: scale(0.96);
  }

  i {
    font-size: 4rem;
  }

  span {
    display: inline-block;
    margin-left: 1.4rem;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #000000;
    font-weight: bold;
  }
}

.p-mr-modal-user-profile-delete {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
  color: black;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
  z-index: 1;

  &:active {
    transform: scale(0.96);
  }

  i {
    font-size: 4rem;
  }

  span {
    display: inline-block;
    margin-left: 1.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    color: #000000;
  }
}

.p-mr-modal-user-avatar-holder {
  position: absolute;
  width: 14.6rem;
  right: -1rem;
  bottom: 20rem;
}

.p-mr-modal-user-avatar-image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.p-mr-modal-change-avatar {
  border: none;
  background: transparent;

  img {
    display: block;
    width: 10.4rem;
    height: auto;
    object-fit: contain;
  }

  .change-your-avatar {
    font-family: 'Allan';
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.7rem;
    text-align: center;
    text-decoration: underline;
    width: 10.4rem;
    flex-wrap: wrap;
    display: flex;
    transform: rotate(-10deg);
    color: #000000;
  }
}

.p-mr-modal-user-profile-logo {
  display: block;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 9rem;
  height: auto;
  object-fit: contain;
}

.p-mr-modal-user-profile-email {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 50px;
  background-color: #ffffff;
  border-radius: 1.6rem;
}

.p-mr-modal-user-profile-form {

  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

}

.p-mr-modal-form-label {
  display: flex;
  flex-direction: column;
  transition: opacity 180ms;
  margin-bottom: 7.2rem;

  &.delete {
    width: 100%;
    margin-bottom: 0;

    input {
      padding: 1.5rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2rem;
      color: rgba(0, 0, 0, 0.7);

      &::placeholder {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }

  span {
    margin-bottom: 3rem;
    font-size: 2.8rem;
    line-height: 3.2rem;
    color: #000000;
  }

  input {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.2rem;
    color: #000000;
    outline: none;
    border: 3.22388px solid #E9E9E9;
    border-radius: 12px;

    &::placeholder {
      font-size: 4rem;
      font-weight: 700;
      line-height: 4.2rem;
      color: #000000;
    }

    &.error {
      border: 2px solid #EB4242;
    }
  }
}

.p-mr-modal-form-btn {
  min-width: 17.7rem;
  height: 5.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 29px;
  background-color: #000000;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: #0000007a;
  }

  span {
    color: #ffffff;
    font-size: 2.1rem;
    font-weight: 600;
    line-height: 2.3rem;
  }
}

.p-mr-modal-close {
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  border: none;
  border-radius: 50%;
  background: none;

  i {
    font-size: 2rem;
  }
}

.p-mr-warning-modal-holder {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000000, 0.45);

  &.open {
    pointer-events: auto;
    opacity: 1;
  }
}

.p-mr-warning-modal {
  position: relative;
  width: 100%;
  max-width: 41.5rem;
  padding: 5.7rem 5.9rem 3.2rem;
  border-radius: 1.6rem;
  background-color: #fff;
}

.p-mr-warning-modal-inside {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.p-mr-warning-modal-txt {
  display: block;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2rem;
  color: #000000;
  text-align: center;
}

.p-mr-warning-modal-btn {
  border: none;
  background: none;

  span {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-transform: uppercase;
    color: #EC7669;
  }
}

.p-mr-event-input-holder {
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  align-items: center;

  &.event-by {
    justify-content: space-between;
  }

  input {
    padding-left: 1rem;
    padding-right: 4.4rem;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.6rem;
    font-size: 1.8rem;
    font-weight: 900;
    line-height: 2.1rem;
    outline: 0.1rem solid transparent;
    background-color: transparent;
    color: black;
    overflow: hidden;
    transition: outline 180ms linear;
    outline: none;

    &.active {
      background-color: #F3F3F3;
      //border-color: #
    }

    &::placeholder {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      color: #000000;
    }

    &:focus {
      //outline: 0.1rem solid rgba(0, 0, 0, 0.2);
    }
  }

  .p-mr-event-input-btn {
    position: absolute;
    top: 0rem;
    right: 1rem;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    border: none;
    background: none;

    i {
      font-size: 1.6rem;
    }

    &.disabled {
      display: none;
      overflow: hidden;
    }
  }
}

.p-mr-modal-top {
  grid-area: p-mr-modal-top;
  min-width: 0;
  min-height: 0;
}

.p-mr-modal-bottom {
  position: relative;
  grid-area: p-mr-modal-bottom;
  min-width: 0;
  min-height: 0;
}

.p-mr-modal-sort {
  z-index: 8;
  position: relative;
  cursor: pointer;

  .p-mr-modal-popover-inside {
    min-width: 19.1rem;
  }
}

.p-mr-modal-sort-svg-btn {
  mask-image: url('../../panda icons/sort-by.svg');
  background-color: #00000080;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  width: 2.4rem;
  height: 2.4rem;
}

.p-mr-modal-sort-btn {
  min-width: 9.3rem;
  height: 3.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  color: #000000;
  border-radius: 4.8rem;
  background: none;
  transition: border 180ms linear;

  &:hover {
    border: 0.1rem solid rgb(0, 0, 0);
  }

  span {
    display: flex;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    .icon-close {
      margin-left: 5px;
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

.p-mr-event-sort-popover-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;

  span {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;
    color: rgba(0, 0, 0, 0.7);
    transition: color 60ms linear, font-weight 60ms ease-in-out;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:hover {
    span {
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
  }
}

.p-mr-lang-popover-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;

  span {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;
    color: rgba(0, 0, 0, 0.7);
    transition: color 60ms linear, font-weight 60ms ease-in-out;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    span {
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
  }
}

.p-mr-user-popover-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.p-mr-user-popover-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background: none;
  text-align: left;
  text-decoration: none;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  width: 100%;
  padding: 5px;

  span {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2rem;
    color: #000000;
    transition: color 60ms linear, font-weight 60ms ease-in-out;
  }

  img {
    display: block;
    max-width: 50px;
    height: auto;
  }

  &.disabled {
    pointer-events: none;
    cursor: auto;

    span {
      font-weight: 400;
      color: rgba(#000000, 0.7);
    }
  }
}

.p-mr-popover-lang {
  position: relative;
}

.p-mr-modal-add {
  flex: 0 0 auto;

  .p-mr-modal-logo {
    display: none;
  }
}

.p-mr-modal-action-holder {
  position: relative;
  flex: 1;
  min-width: 0;

  &.hide {
    display: none;
  }
}


@keyframes slide {
  from {
    right: 15%;
  }
  to {
    right: 27%;
  }
}

.p-mr-modal-search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  background: none;

  i {
    mask-image: url('../../panda icons/search.svg');
    //background-color: #00000080;
    background-color: #ffffff;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover {
    i {
      color: grey;
    }
  }
}

@keyframes search {
  from {
    right: 15%;
  }
  to {
    right: 27%;
  }
}


.p-mr-modal-add-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem;
  width: 12.5rem;
  border: none;
  border-radius: 4.8rem;
  background-color: #E5F96F;
  text-decoration: none;
  color: #000000;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;

  i {
    font-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    background-image: url("../../panda icons/plus.svg");
    filter: invert(1);
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-mr-modal-user {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  .p-mr-modal-popover-inside {
    min-width: 19.1rem;
  }
}

.DraftIcon {
  width: 2.4rem;
  height: 2.4rem;
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.p-mr-modal-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  background: none;
  color: #000000;
  cursor: pointer;
  transition: color 60ms linear;

  .icons {
    width: 2.4rem;
    height: 2.4rem;
    fill: white;
    opacity: 1;

    &.active {
      //path, g {
      //  fill: #E5F96F;
      //}

      .content {
        fill: none;
      }
    }
  }

  .friends {
    width: 2.4rem;
    height: 2.4rem;

    &.active {
      //path{
      //  stroke: #E5F96F;
      //}
    }
  }

  //i {
  //  background-image: url("../../icons/profile.svg");
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //  width: 2.4rem;
  //  height: 2.4rem;
  //}

  //&:hover {
  //  color: grey;
  //}
}

.p-mr-modal-tabs {
  width: 100%;
  opacity: 1;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 5rem;
  border-radius: 0.8rem;
  overflow: hidden;
  background-color: #f3f3f3;
  transition: opacity 120ms ease-in-out;
}

.p-mr-modal-tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: transform 180ms ease-in-out;

  &.fastex {
    background-color: #FF008C;
  }

  &.active {
    cursor: default;

    .p-mr-modal-tab-txt {
      color: #FFFFFF;
    }
  }
}

.p-mr-modal-tab:first-child:nth-last-child(3).active ~ .p-mr-modal-tab-bg {
  width: 49.999%;
  transform: translateX(0);
}

.p-mr-modal-tab:nth-child(2):nth-last-child(2).active ~ .p-mr-modal-tab-bg {
  width: 49.999%;
  transform: translateX(100%);
}

.p-mr-modal-action-holder {
  .p-mr-modal-tab, .p-mr-space-tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 180ms ease-in-out;

    &.active {
      cursor: default;

      .p-mr-modal-tab-txt {
        color: #FFFFFF;
      }
    }
  }

  .p-mr-modal-tab:first-child.active ~ .p-mr-modal-tab-bg {
    width: 33.333%;
    transform: translateX(0);
  }

  .p-mr-modal-tab:nth-child(2).active ~ .p-mr-modal-tab-bg {
    width: 33.333%;
    transform: translateX(100%);
  }

  .p-mr-modal-tab:nth-child(3).active ~ .p-mr-modal-tab-bg {
    width: 33.333%;
    transform: translateX(200%);
  }

  .p-mr-space-tab:first-child.active ~ .p-mr-modal-tab-bg {
    width: 49.999%;
    transform: translateX(0);
  }

  .p-mr-space-tab:nth-child(2).active ~ .p-mr-modal-tab-bg {
    width: 49.999%;
    transform: translateX(100%);
  }
}

.p-mr-modal-tab-txt {
  position: relative;
  z-index: 8;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.3rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: color 180ms linear;
  border: none;
  background-color: transparent;

  &.fastex {
    font-weight: 500;
  }
}

.p-mr-modal-tab-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 0.8rem;
  background-color: #000000;
  transition: transform 180ms linear;

  &.fastex {
    background-color: #FF008C;
  }
}

.p-mr-events-list {
  grid-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(2,12fr);
  justify-items: center;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5rem;
  padding-right: 0.5rem;
  scrollbar-gutter: stable;
  width: 100%;

  &:empty {
    & + .p-mr-empty-state {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.p-mr-event-holder {
  width: 100%;
  height: 100%;
  position: relative;
}

.p-mr-event-top-info {
  position: relative;
  width: 100%;
  height: 100%;
}

.p-mr-event-popover-holder {
  position: absolute;
  z-index: 7;
  top: 1rem;
  right: 1.8rem;

  .p-mr-modal-popover {
    top: 2.5rem;
  }
}

.p-mr-event-meatballs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  background: none;
  border: none;

  i {
    width: 2.4rem;
    height: 2.4rem;
    text-shadow: 2px 2px 6px #000000;
    background-image: url("../../panda icons/more.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.p-mr-event-popover-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  background: none;
  color: #0d0c22;
  transition: color 180ms linear;
  position: relative;

  &.tooltip {
    .tooltiptext {
      display: none;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px;
      border-radius: 6px;

      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      right: -35px;
      margin-left: -60px;

      &.active {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s 3s, opacity 3s linear;
      }

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }
  }

  i {
    font-size: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:hover {
    color: rgba(0, 0, 0, 1);
  }

  .icon-trash, .icon-edit, .icon-link {
    background-color: rgb(var(--revert-color));
  }

  &.disabled {
    color: rgb(178 168 168 / 70%);
    cursor: default;

    .icon-trash, .icon-edit, .icon-link {
      background-color: rgba(178, 168, 168, 0.7);
    }
  }
}

.img-container {
  box-shadow: inset 0 0 20px 15px rgba(65, 65, 65, 0);
  z-index: 1;
  transform: scaleX(1);
  transition: all .1s linear;
  cursor: pointer;

  &:hover {
    transform: scale3d(1.1, 1.1, 1);
  }
}

.p-mr-event-image {
  display: block;
  max-width: 100%;
  height: 16rem;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  margin-right: 0;
  width: 100%;
}

.p-mr-event-info {
  width: 100%;
}

.p-mr-event-btn {
  position: absolute;
  bottom: 0.4rem;
  right: 1rem;

  &.flex-center {
    display: flex;
    align-items: self-end;
    flex-direction: column;
    color: #EC7669;
  }
}

.p-mr-show-more-btn {
  padding: 0;
  border: none;
  border-bottom: 0.2rem solid #EC7669;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #EC7669;
  background: none;
  text-transform: capitalize;
  display: none;
}

.p-mr-event-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4.6rem;
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.2rem;
  text-transform: capitalize;
  color: #000000;

  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.p-mr-event-by {
  max-width: 28rem;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 2rem;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 1rem;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-mr-event-bottom-info {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-column-gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.7rem;
  border-top: 0.1rem solid rgba(#c4c4c4, 0.3);
  transition: opacity 180ms linear;
  padding: 10px;
}

.p-mr-event-add-info-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.p-mr-event-add-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.5rem;

  i {
    color: #F2994A;
    font-size: 1.8rem;
  }
}

.p-mr-event-add-image {
  max-width: 100%;
  max-height: 15rem;
  border-radius: 1.2rem;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.p-mr-event-add-info-title {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #6d6d6d;
  text-transform: capitalize;
}

.p-mr-event-add-info-value {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
  color: #000000;
}

.p-mr-empty-state {
  position: absolute;
  top: 0;
  left: 0;
  //z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    display: block;
    width: 16rem;
    height: auto;
    object-fit: contain;
    transform: translate3d(3rem, 0, 0);
    margin-bottom: 2rem;
  }
}

.p-mr-empty-state-title {
  margin-bottom: 3rem;
  font-weight: 900;
  font-size: 26px;
  line-height: 3.4rem;
  color: #fff;
  text-transform: capitalize;
}

.p-mr-empty-state-subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 2.6rem;
  color: #fff;
  margin-bottom: 2rem;
}

.p-mr-empty-state-space-title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  color: #fff;
  margin-bottom: 2rem;
}

.p-mr-empty-state-space-content {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #fff;
  margin-bottom: 2rem;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .p-mr-event-title {
    display: flex;
  }
  .space-loader {
    .space-spinner, .space-container {
      width: 72.5vw;
      height: 72.5vw;
    }
  }
  .right-container {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    background: #ffffffba;
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .description {
      text-align: center;
    }

    .category-container {

      display: flex;
      justify-content: center;
      align-items: center;

      .category {
        margin: 15px;
        height: 50px;
        line-height: 50px !important;
      }
    }
  }
}


//EventsModal -- End

//Participants -- Start

.participants-wrp {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: "participantTop" "participantCenter" "participantBottom";
  padding: 3.2rem 6rem;
}

.participants-top-wrp {
  width: 100%;
  height: 100%;
  grid-area: participantTop;
}

.participants-mid-wrp {
  width: 100%;
  height: 100%;
  grid-area: participantCenter;
}

.participants-bottom-wrp {
  width: 100%;
  height: 100%;
  grid-area: participantBottom;
}

.participants-top-cont {
  width: 100%;
  padding: 0 10rem;
  position: relative;
}

//SocialButton START
.participants-btn {
  border-radius: 50%;
  background-color: rgba(#000000, 0.2);
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;

  &:nth-child(2) {
    margin: 0 1.4rem;
  }

  &.p-top-btn {
    background-color: rgba(#000000, 0.21);
  }

  &.settings {
    background-image: url("./../../images/settings.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.freeze {
    background-color: #EB4242;
  }
}

.p-mid-btn {
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 1.2rem;

  &:last-child, &:only-child {
    margin: 0;
  }
}

.p-big-btn {
  width: 6rem;
  height: 6rem;
  margin-right: 1.4rem;

  &:last-child, &:only-child {
    margin: 0;
  }
}

.p-extra-big-btn {
  width: 8rem;
  height: 8rem;
  margin: 0 1.8rem 0 0.4rem;

  &:last-child, &:only-child {
    margin: 0;
  }

  &.active {
    background-color: #000000;
  }
}

.left-cl-btn-wrp {
  gap: 15px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;

  .audio-video, .video-audio {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.right-cl-btn-wrp {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

//SocialButton END

.root-authorization-variant-li {
  .social-btn {
    width: 100% !important;
    height: 100% !important;
    border-radius: 30px !important;
    background: transparent !important;
    border: none !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 11 !important;
    opacity: 0 !important;
  }

}

.design-department-wrp {
  text-align: center;
}

.design-depart-cont {
  min-width: 5.4rem;
  height: 5.4rem;
  padding: 0.6rem;
  border-radius: 2.8rem;
  background-color: rgba(#ffffff, 0.8);
  position: relative;
  margin-bottom: 0.6rem;
  display: inline-flex;
  align-items: center;

  &.add-amount-cont {
    padding-right: 1.6rem;
  }
}

.participants-avatar-wrp {
  max-width: 4.4rem;
  width: 100%;
  height: 4.4rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  cursor: pointer;

  &:nth-child(1) {
    z-index: 6;
  }

  &:nth-child(2) {
    z-index: 3;
    margin-left: -2rem;
  }

  &:nth-child(3) {
    z-index: 1;
    margin-left: -2rem;
  }
}

.participant-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.additional-amount {
  min-width: 2.4rem;
  height: 1.6rem;
  border-radius: 2.2rem;
  background-color: #000000;
  position: absolute;
  bottom: 0.4rem;
  right: 0.9rem;
  text-align: center;
  z-index: 9;
  cursor: pointer;

  .amount {
    font-size: 1.3rem;
    color: #ffffff;
    line-height: 1.6rem;
  }
}

.design-depart-text {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;
  color: #ffffff;
  text-shadow: 0 0.2rem 1.1rem rgba(0, 0, 0, 0.5);
  text-align: center;
}

.participants-bottom-cont {
  width: 100%;
  padding: 0 12rem;
  position: relative;
}


.main-btns-wrp {
  display: flex;
  align-items: center;
  justify-content: center;

  .laptop-icon {
    font-size: 2.4rem;
  }

  .edit-icon {
    font-size: 1.8rem;
  }

  .plus-icon {
    font-size: 2.2rem;
  }

  .search-icon {
    font-size: 2rem;
  }

  .home-icon {
    font-size: 2rem;
  }
}

.btn-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.participants-popup-wrp {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

.participants-popup {
  width: 40.4rem;
  height: 42.8rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2rem;
  padding: 3rem;
  padding-right: 1.5rem;
}

.participants-popup-cont {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 11fr;
  grid-gap: 2.8rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.p-popup-title-box {
  width: 100%;
  position: relative;

  &:after {
    content: "x";
    position: absolute;
    top: 0;
    right: 0;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.15rem solid #FFFFFF;
    border-radius: 0.7rem;
    color: #FFFFFF;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.p-popup-title {
  font-size: 1.8rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.2;
  padding-right: 1.5rem;
}

.p-popup-main-cont {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 1.5rem;
}

.popup-participant-box {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: rgba(#6D6D6D, 0.1);
  }
}

.popup-user-avatar {
  flex: 0 0 auto;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border: 0.15rem solid #E5F96F;
  }
}

.no-img-avatar {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background-color: #BB6BD9;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.408px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
}

.popup-user-img {
  width: 3.6rem;
  height: 3.6rem;
  object-fit: cover;
  display: block;
}

.popup-user-name {
  flex: 1;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 0 1.2rem;
}

.popup-user-vol {
  flex: 0 0 auto;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  border: 0.05rem solid #FFFFFF;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 240ms, background-color 240ms;
  cursor: pointer;

  &.muted {
    border: none;
    background-color: #EB4242;
  }

  span {
    color: #FFFFFF;
  }

  .mute-icon {
    font-size: 2.4rem;
  }
}

.join {
  display: none !important;

  video {
    display: none !important;
  }

  &.active {
    display: flex !important;

    video {
      display: inline-block !important;
    }
  }

}

//Participants -- End

//Audio And Video -- Start

.audio-video-popup {
  max-width: 107.2rem;
  width: 100%;
  height: 68.6rem;
  border-radius: 2rem;
  background-color: rgba(#000000, 0.7);
  padding: 2.2rem 2.6rem;
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: "AudioVidTop" "AudioVidMid" "AudioVidBottom";

}

.audio-vid-popup-top {
  width: 100%;
  grid-area: AudioVidTop;
}

.audio-vid-popup-mid {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: AudioVidMid;
  flex-direction: column;
  position: relative;

  .video-img {
    background-image: url("./../../images/video.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .remote-player-wrapper, .remote-player-screen {
    display: none;
  }

  .remote-player-wrapper {
    .video-player {
      display: none !important;
      //width: 20px !important;
      //height: 20px !important;
    }
  }

  .video-player, .video-img {
    width: 320px !important;
    height: 320px !important;
    position: relative !important;
    top: 0 !important;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      border-radius: 50%;
    }

    video {
      border-radius: 50%;
    }
  }
}

.audio-vid-popup-bottom {
  width: 100%;
  padding: 3.4rem 1rem;
  grid-area: AudioVidBottom;
}

.audio-vid-p-bottom-box {
  width: 100%;
  position: relative;
  padding: 0 11rem;
}

.vid-place-cont {
  max-width: 34.4rem;
  width: 100%;
  cursor: pointer;

  > img {
    width: 100%;
  }
}

.mid-cl-btn-wrp {
  text-align: center;
}

.join-btn {
  width: 12.8rem;
  height: 4.8rem;
  border-radius: 5.6rem;
  background: #E5F96F;
  border: none;
  font-size: 1.6rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 1.5rem;
  cursor: pointer;

  &.create {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  &.disabled {
    background: #e5f96f63;
  }

  &.create {
    height: unset;
    width: unset;
    padding: 1.8rem 4rem;
  }
}

.screenShare {
  position: absolute;
  top: 0;
  display: none;
}

.continue-btn {
  background: transparent;
  border: none;
  font-size: 1.3rem;
  font-weight: 400;
  color: #FFFFFF;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}

//settings START
.settings-wrp {
  width: 100%;
  height: 100%;
  padding: 6.4rem 1.6rem 0 1.6rem;
}

.settings-body-cont {
  width: 100%;
}

.settings-title {
  font-size: 2.4rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.3;
  text-align: center;
}

.settings-box {
  width: 100%;
  height: 7.2rem;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: rgba(#C4C4C4, 0.2);
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
}

.settings-item {
  width: 100%;

  &.right {
    text-align: right;
  }
}

.settings-item-bl {
  position: relative;
  cursor: pointer;

  &.active, &:hover {
    .settings-text {
      color: #FFFFFF;
    }

    .arrow-icon {
      color: #FFFFFF;
      transform: rotate(-180deg);
    }
  }
}

.settings-text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  color: rgba(#ffffff, 0.5);
  transition: color 240ms;

  &.sub-title {
    color: #FFFFFF;
  }

  > span {
    margin-left: 1rem;
  }
}

.settings-dd-wrp {
  width: 13.5rem;
  height: 14.8rem;
  border-radius: 0.8rem;
  background: #FFFFFF;
  box-shadow: 0 0.5rem 1.2rem rgba(90, 95, 105, 0.2);
  position: absolute;
  bottom: 3rem;
  right: 0;
  padding: 1.6rem 2rem;
  display: none;
}

.settings-dd-text {
  font-size: 1.3rem;
  line-height: 1.6;
  font-weight: 400;
  color: rgba(#000000, 0.7);
  transition: color 240ms, font-weight 240ms;
  text-align: right;
  margin-bottom: 1.2rem;

  &.active, &:hover {
    color: #000000;
    font-weight: 600;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.space-manager-container {
  position: absolute;
  bottom: 1px;
  width: 65% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.enter-space-btn {
  margin-top: 20px;
  background: #FF6900;
  border-radius: 108px;
  width: 254px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  color: white;
  z-index: 1;
  border: none;
  user-select: none;
}

@media screen and (max-width: 700px) {
  .enter-space-btn {
    display: none;
  }
}

.unity-layout {

  display: none;

  &.active {
    display: block;
  }

  .unity-canvas {
    width: 100%;
    height: 100%;
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .unity-controllers {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    height: 50vh;
    width: 25vw;
    max-width: 500px;
    border-top-left-radius: 80px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.unity-board-participants-block {
  width: 100%;
  margin-top: 1.3rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 2rem;
  padding: 3.5rem 3rem 2.5rem 3.4rem;
  position: relative;

  .search-holder {
    width: 100%;
    height: 40px;
    position: relative;
    margin-bottom: 1rem;

    .search-input {
      background: transparent;
      color: #fff;
      width: 100%;
      height: 100%;
      border: 1.5px solid #ffffff69;
      border-radius: 10px;
      padding-left: 8px;
      &:focus {
        outline: none;
      }
    }

    .search-btn {
      position: absolute;
      font-size: 20px;
      background: transparent;
      right: 8px;
      top: 8px;
      border: none;
      outline: none;
      cursor: pointer;

      .icon-search {
        background-color: #ffffff69;
      }
    }
  }
}

.unity-board-participants-title {
  margin-top: 0;
  margin-bottom: 2.9rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #ffffff;
  text-shadow: 0 0.2rem 1.1rem rgba(0, 0, 0, 0.5);
}

.unity-board-participants-close {
  font-size: 2rem;
  color: #ffffff;
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: transparent;
  border: none;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-close {
    background-color: white;
  }
}

.speaker-container {
  position: absolute;
  top: 20%;
  left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  z-index: 2;

  .speaker {
    display: none;

    &.active {
      display: flex;
      color: white;
      padding: 15px;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 8px;
    }

    span {
      margin-right: 15px;
    }

    .volume {
      path:nth-child(1) {
        stroke: #00FF00;
      }

      &.minVol {
        path:nth-child(3) {
          stroke: #00FF00;
        }
      }

      &.maxVol {
        path:nth-child(3) {
          stroke: #00FF00;
        }

        path:nth-child(2) {
          stroke: #00FF00;
        }
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    border-color: transparent;
  }
  25% {
    border-color: rgba(51, 108, 255, 0.38);
  }
  50% {
    border-color: rgba(51, 108, 255, 0.82);
  }
}

.unity-board-participants-li {
  width: 100%;
  height: 6.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 40px;
  border: 1px solid transparent;
  gap: 10px;

  h1 {
    color: white;
    font-size: 3rem;
    border: 1px solid grey;
    padding: 11px;
    border-radius: 50%;
    line-height: 22.5px;
    width: 45px;
    height: 45px;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.1rem;
      background-color: rgba(109, 109, 109, 0.1);
    }
  }

  .userInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .audioBtns {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.unity-board-participant-avatar {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}


.unity-board-element-edit-window {
  width: 38.8rem;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5.6rem 1.4rem 4.3rem 2.4rem;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 1.2rem 0 0 0;
  display: none;
  height: 0;

  &.active {
    display: block;
    height: auto;
  }
}

.unity-board-element-edit-close {
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  width: 2rem;
  height: 2rem;
  border: none;
  outline: none;
  font-size: 2rem;
  color: #000000;
  background-color: transparent;

  i {
    display: block;
    top: 0;
    right: 0;
  }
}

.unity-board-element-edit-section {
  width: 100%;
  margin-bottom: 4.3rem;
}

.unity-board-element-edit-panel {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #000000;
    text-transform: capitalize;
  }

  .unity-board-element-dimension {
    display: flex;
    justify-content: flex-start;
    padding-left: 1.3rem;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      color: #000000;
      text-transform: uppercase;
      box-sizing: border-box;
      cursor: pointer;

      &.active {
        border: 0.1rem solid #000000;
        cursor: default;
      }

      &:not(:last-child) {
        margin-right: 0.7rem;
      }
    }
  }

  button {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: #6d6d6d;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.unity-board-element-edit-range {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;

  input {
    width: 100%;
    flex: 1;
    -webkit-appearance: none;
    margin: 1.8rem 0;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.4rem;
      cursor: pointer;
      box-shadow: none;
      background: rgba(#EC7669, 0.1);
      border-radius: 0.2rem;
      border: none;
    }

    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 0.3rem rgba(#EC7669, 0.2);
      border: none;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: #EC7669;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -0.3rem;
    }

    &:focus::-webkit-slider-runnable-track {
      background: rgba(#EC7669, 0.1);
    }

    &::-moz-range-track {
      width: 100%;
      height: 0.4rem;
      cursor: pointer;
      box-shadow: none;
      background: rgba(#EC7669, 0.1);
      border-radius: 0.2rem;
      border: none;
    }

    &::-moz-range-thumb {
      box-shadow: 0 0 0 0.3rem rgba(#EC7669, 0.2);
      border: none;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: #EC7669;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -0.3rem;
    }

    &::-ms-track {
      width: 100%;
      height: 0.4rem;
      cursor: pointer;
      box-shadow: none;
      background: rgba(#EC7669, 0.1);
      border-radius: 0.2rem;
      border: none;
    }
  }

  span {
    display: block;
    min-width: 3.8rem;
    margin-left: 0.6rem;
    flex: 0 0 auto;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #000000;
  }
}

.unity-board-element-edit-step-action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-left: 1.9rem;
    margin-right: 1.9rem;
    width: 3.2rem;
    height: 3.2rem;
    outline: none;
    display: block;
    padding-left: 0;
    padding-right: 0;
    border: 0.1rem solid #000000;
    border-radius: 0.3rem;
    background-color: transparent;

    &.active {
      background-color: #000000;
      color: #ffffff;
    }

    i {
      display: block;
      margin: auto;
    }

    &:nth-child(1) {
      transform: rotateY(180deg);
    }
  }
}


//settings END

//icons START
.arrow-icon {
  font-size: 1.4rem;
  color: rgba(#ffffff, 0.5);
  transition: color 240ms, transform 240ms;
}

//icons END

//Audio And Video -- End

.panda-my-space-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 11fr;
  grid-row-gap: 2.2rem;
}

.panda-my-space-tabulation {
  width: 100%;
  height: 5.6rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
  grid-column-gap: 1.8rem;
  background-color: #F3F3F3;
  border-radius: 8px;
  padding: 0.3rem;
  box-sizing: border-box;

  button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    text-transform: capitalize;
    transition: color 240ms, background-color 240ms;
    border-radius: 0.8rem;

    &.active {
      background-color: #000000;
      color: #FFFFFF;
    }

    &:not(.active):hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: #FFFFFF;
    }

    &.disabled {
      pointer-events: none;

      span {
        opacity: 0.4;
      }
    }

    &.with-icon {
      &:before {
        content: "";
        width: 3.5rem;
        height: 2.9rem;
        display: inline-block;
        background-image: url("./../../images/lightning-max.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 0 50%;
        margin-right: -1.2rem;
      }
    }
  }
}

.panda-my-space-list-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.panda-my-space-list-inner {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.panda-my-space-list {
  padding-right: 1rem;
  position: relative;
  height: 100%;
  overflow: hidden;

  .filter_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ucraft-header {
      z-index: 11;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.3rem;
      color: #FFFFFF;
    }
  }

  .card-container {
    overflow-y: auto;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    height: calc(100% - 5.6rem);
    margin-top: 3rem;

    &.integrations {
      grid-template-columns: repeat(6, 1fr);
      height: calc(100% - 6.9rem);
    }

    &.empty {
      grid-template-columns: none;
      grid-gap: 0;
    }
  }
}

.panda-my-space-list-el {
  width: 100%;
  height: 23rem;
  position: relative;
  border-radius: 1.2rem;
  overflow: hidden;
  perspective: 100rem;
  cursor: pointer;
  //padding: 5px;
  border: 1px solid #c7c7c7;
  .checkbox {
    position: absolute;
    right: 5px;
    top: 5px;
    path:nth-child(2) {
      stroke: none;
    }
  }
  &.selected {
    border: 1px solid black;
    .checkbox {
      path:nth-child(2) {
        stroke: black;
      }
    }
  }

  &:hover {
    .panda-my-space-overlay-info {
      transform: rotateX(0);
      opacity: 1;
      transition-delay: 0.2s;
    }

    .panda-my-space-nft-label {
      opacity: 0;
      transition-delay: 0.2s;
    }
  }

  .ecommerce-products {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;

    .ecommerce-products-desc {
      margin: 1rem 0;
      h3 {
        margin-left: 1rem;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span {
        margin-left: 1rem;
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .panda-my-space-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;

    &.stuff-image {
      object-fit: contain;
    }
  }
}

.panda-my-space-play-el {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;

  i {
    background-color: rgb(var(--main-color));
    width: 2rem;
    height: 2rem;
  }
}

.panda-my-space-remove-el {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
  background-color: #ffffff00;
  border: none;
  outline: none;
  z-index: 9;
  border-radius: 50%;

  i {
    top: 0;
    right: 0;
    background-color: rgb(var(--main-color));
    width: 2rem;
    height: 2rem;
  }
}

.panda-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 9;

  i {
    top: 0;
    right: 0;
  }
}


.panda-my-space-overlay-info {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.54);
  padding: 3rem 1.6rem 2.7rem;
  box-sizing: border-box;
  transform-origin: bottom;
  transform: rotateX(90deg);
  opacity: 0;
  transition: transform 320ms cubic-bezier(.48, 0, .14, 1.65), opacity 160ms linear;
}

.panda-my-space-nft-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2rem;
  line-height: 2.1rem;
  color: #ffffff;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.panda-my-space-nft-description {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #ffffff;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.panda-my-space-nft-link {
  width: 100%;
  padding-bottom: 2.5rem;
  position: relative;
}

.panda-my-space-nft-link-text {
  width: 100%;
  padding: 0.7rem 0.7rem 0.5rem;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.6rem;
  word-break: break-all;
  font-size: 1rem;
  line-height: 1.2rem;
  font-style: italic;
  font-weight: 600;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

.panda-my-space-nft-link-btn, .panda-my-space-nft-info {
  background-color: transparent;
  border: none;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  font-size: 1.2rem;
}

.panda-my-space-nft-info {
  right: 2.4rem;
}

.panda-my-space-nft-price {
  position: absolute;
  bottom: 2.7rem;
  left: 1.7rem;
  font-size: 2.4rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: #ffffff;
}

.panda-my-space-nft-label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 2.4rem;
  border-radius: 0.6rem;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 1.2rem;
  font-weight: 900;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  transition: opacity 240ms;
}

.my-space-links-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  padding: 3rem;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.my-space-links-popup-body {
  margin: auto;
  width: 100%;
  max-width: 48.2rem;
  padding: 3.6rem 3.2rem 3rem;
  box-sizing: border-box;
  border-radius: 0.6rem;
  background-color: #ffffff;
  position: relative;
}

.my-space-links-popup-close {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 2rem;
  color: #000000;
}

.my-space-links-popup-title {
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 6.4rem;
  font-size: 2.8rem;
  color: #000000;
  font-weight: 700;
}

.my-space-links-list {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.my-space-links-list-el {
  &:not(:last-child) {
    margin-bottom: 1.8rem;
  }
}

.my-space-links-list-el-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #000000;
  font-weight: 700;
}

.my-space-links-list-el-link-wrapper {
  width: 100%;
  padding: 1.9rem 6.4rem 1.8rem 1.8rem;
  box-sizing: border-box;
  background-color: rgba(0, 134, 255, 0.07);
  border-radius: 0.6rem;
  position: relative;
}

.my-space-links-list-el-link-text {
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-all;
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #0086FF;
}

.my-space-links-list-el-link-copy {
  position: absolute;
  right: 1.4rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  background-color: transparent;
  border: none;
  outline: none;
  color: #0086FF;
}

.panda-my-space-uploader-block {
  width: 100%;
  margin-bottom: 1.6rem;
}

.panda-my-space-uploader-label {
  display: inline-flex;
  height: 4.1rem;
  border-radius: 2.1rem;
  justify-content: center;
  align-items: center;
  padding-left: 4.8rem;
  padding-right: 1.9rem;
  position: relative;
  border: 0.1rem solid #000000;
  cursor: pointer;

  input {
    display: none;
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.9rem;
    font-size: 2rem;
  }
}

.panda-my-space-uploader-text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #000000;
}

.panda-my-space-upload-window {
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone {
  padding: 35px;
  color: white;
  border: 1px dashed #CACACA;
  border-radius: 16px;
  user-select: none;

  &.disabled {
    pointer-events: none;
  }

  &.uploading_err {
    border: 1px dashed #EB4242;
  }
}

.panda-my-space-upload-block {
  width: 44rem;
  height: 32rem;
  padding: 6.6rem 3.6rem 5.9rem;
  box-sizing: border-box;
  border-radius: 1.6rem;
  border: 1rem dashed #e9e9e9;
  position: relative;


  &:after {
    content: "";
    position: absolute;
    top: -0.9rem;
    left: -0.9rem;
    width: calc(100% + 1.8rem);
    height: calc(100% + 1.8rem);
    background-color: #ffffff;
    border-radius: 1.6rem;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

.panda-my-space-upload-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 6.7rem;
  font-size: 2rem;
  line-height: 2.1rem;
  font-weight: 900;
  text-align: center;

  &.error {
    color: #EB4242;
  }
}

.panda-my-space-upload-label-wrapper {
  width: 100%;
  text-align: center;
}

.panda-my-space-upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20.6rem;
  max-width: 100%;
  height: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 3.8rem;
  background-color: #FF6900;
  font-size: 1.6rem;
  font-weight: 700;
  color: #FFFFFF;
  cursor: pointer;

  input {
    display: none;
  }
}

.panda-my-space-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.panda-my-space-upload-description {
  width: 100%;
  text-align: center;
  margin-top: 1.9rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: 600;
}


//New Space Styles -- Start

.new-space-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 11fr;
  grid-row-gap: 4.2rem;
}

.new-space-switcher {
  width: 100%;
  height: 5rem;
  max-width: 68.8rem;
  margin-bottom: 15px;
  background-color: #F3F3F3;
  border-radius: 0.8rem;

  .back {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;

    &:active {
      transform: scale(0.96);
    }

    .icon-close {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

.new-space-switcher-btn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: 600;
  color: #0d0c22;
  cursor: pointer;

  &.active {
    background-color: #D7D7D7;
    color: #0d0c22;
    cursor: default;
  }
}

.new-space-body {
  width: 100%;
  height: 100%;
  position: relative;
}

.new-space-body-inner {
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.new-space-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 2.4rem;
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 600;
  color: #000;
}

.new-space-el-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.7rem;
  grid-row-gap: 1.2rem;
  padding: 10px;
  padding-bottom: 5rem;
}

.new-space-el {
  width: 100%;
  height: 24rem;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #FFFFFF;
  border-radius: 1.2rem;
  overflow: hidden;
}

.new-space-el-info-col {
  width: 18.9rem;
  flex: 0 0 auto;
  padding: 2.4rem 1.5rem 2.5rem 2.4rem;
  box-sizing: border-box;
  background: #212121;

  span {
    cursor: pointer;
  }
}

.new-space-el-thumbnail {
  display: block;
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  margin-bottom: 2.1rem;
}

.new-space-el-image-col {
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;


  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all .1s linear;
    transform: scaleX(1);

    &:hover {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
}

.new-space-el-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.8rem;
  font-size: 2rem;
  line-height: 2.1rem;
  color: #fff;
  font-weight: 900;
}

.new-space-el-link {
  height: 2.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: #fff;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: #fff;
  }
}

//New Space Styles -- End

//UNITY BOARD -- Start
.unity-board {
  user-select: none;
}

.unity-board-auth-block {
  position: absolute;
  top: 3.8rem;
  left: 6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
}

.unity-board-logout {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.7rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:only-child) {
    margin-right: 2.6rem;
  }
}

.unity-drafted {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.8rem;
  border-radius: 1.4rem;
  text-transform: uppercase;
  background-color: #ec7669;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #ffffff;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.9rem;
  user-select: none;
}

.unity-board-user-list-block {
  position: absolute;
  top: 3.2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  z-index: 1;
}

.unity-board-user-list {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 5.4rem;
  border-radius: 2.7rem;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  cursor: pointer;

  &[data-more-count] {
    &:not([data-more-count=""]) {
      padding-right: 1.7rem;

      &:after {
        content: attr(data-more-count);
        position: absolute;
        right: 1rem;
        bottom: 0.4rem;
        height: 1.6rem;
        border-radius: 0.8rem;
        background-color: #000000;
        color: #ffffff;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.unity-board-user-li {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  position: relative;
  border: 0.1rem solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  h1 {
    font-size: 2.8rem;
    color: grey;
  }

  &:not(:only-child):not(:first-child) {
    margin-right: -1.9rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}


.unity-board-content {
  transition: opacity 180ms;
  height: 0;
  opacity: 0;
  overflow: hidden;

  &.active {
    opacity: 1;
    height: 100%;
    width: 100%;
  }
}

.unity-board-title {
  width: 100%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0.2rem 1.1rem rgba(0, 0, 0, 0.5);
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.unity-board-action-panel {
  position: absolute;
  top: 3.8rem;
  right: 6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .takeScreenShot {
    background-image: url("../../../assets/icons/screenShot.svg");
    width: 42px;
    height: 42px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 1.2rem;
  }
}

.invite-popup {
  padding: 20px;
  position: absolute;
  z-index: 11111;
  right: 3.5%;
  top: 10%;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  font-size: 14px;
}

.unity-board-invite {
  background-image: url("../../../assets/icons/profile_add.svg");
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.7rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
}

.unity-board-action-button {
  margin-right: 1.2rem;
  height: 4.2rem;
  border-radius: 2.1rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  font-size: 1.6rem;
  padding-top: 0.3rem;
}

.shopping-card {
  background-image: url("../../../assets/icons/shoppingCard.svg");
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 1.2rem;
}

.unity-board-more-btn {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.25rem 0.75rem;

  .unity-board-more-icon {
    background-image: url("../../../assets/panda icons/more.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  & > i {
    color: #ffffff;
    font-size: 1.4rem;
    display: block;
    cursor: pointer;
  }
}

.unity-more-button-menu {
  width: 24.5rem;
  height: 0;
  position: absolute;
  right: 0;
  top: 5.4rem;
  background-color: #ffffff;
  border-radius: 0.8rem;
  padding: 2.6rem 2rem 2.6rem 2.4rem;
  box-sizing: border-box;
  display: none;
  transition: 0.3s;

  &.active {
    display: block;
    height: auto;
  }
}

.unity-more-button-menu-el {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #000000;
  font-weight: 600;
  text-align: left;
  position: relative;
  padding-right: 3rem;
  padding-left: 0;
  text-decoration: none;
  display: block;

  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &.tooltip {
    .tooltiptext {
      display: none;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px;
      border-radius: 6px;

      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      right: -2.5rem;
      margin-left: -60px;

      &.active {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s 3s, opacity 3s linear;
      }

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }
  }

}

.unity-board-media-panel {
  gap: 15px;
  position: absolute;
  left: 6rem;
  bottom: 0;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 50px;

  .audio-video, .video-audio {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.unity-board-media-button {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;

  &:not(:last-child) {
    margin-right: 1.4rem;
  }

  i {
    font-size: 1.6rem;
    color: #ffffff;
    display: block;
  }
}

.unity-board-button-panel {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  gap: 10px;
  bottom: 0;
  margin-bottom: 50px;

  button {
    outline: none;
  }
}

.unity-board-portal {
  .p-mr-modal-holder {
    background: #0000003d;
  }

  .p-mr-modal-search-holder {
    margin-right: 10rem;

    &.active {
      margin-right: 14rem;
    }
  }

  .portal-popup-close {
    position: absolute;
    top: 1.8rem;
    right: 1.8rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 2rem;
    height: 2rem;
    mask: url("../../panda icons/close.svg") no-repeat;
    mask-size: contain;
    background-color: #000;
  }
}

.unity-board-button-el {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-left: 0.7rem;
  //margin-right: 0.7rem;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;

  &:has(.plus-button) {
    width: 8rem;
    height: 8rem;
    background-color: rgb(0, 0, 0);
  }

  //&:not(.chat-icon) {
  //  margin: 0 0.7rem;
  //}

  .chat-icon {
    width: 2.4rem;
    height: 2.4rem;
    background-color: white;
    mask-image: url('../../panda icons/chat.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;

    &.freeze {
      mask-image: url('../../panda icons/tweet-off.svg');
    }
  }

  &.freeze {
    background-color: #EB4242;
  }

  .portal {
    width: 2.4rem;
    height: 2.4rem;
    background-color: white;
    mask-image: url('../../panda icons/portal.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  .plus-button {
    width: 3rem;
    height: 3rem;
    mask-image: url('../../panda icons/plus.svg');
    background-color: white;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  .search-button {
    width: 2.4rem;
    height: 2.4rem;
    background-color: white;
    mask-image: url('../../panda icons/search.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  .icon-laptop {
    width: 2.4rem;
    height: 2.4rem;
    background-color: white;
    mask-image: url('../../panda icons/sharescreen.svg');
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &.active {
    background-color: rgb(0 0 0 / 62%);
  }
}

.unity-board-search-popover {
  position: fixed;
  bottom: 6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 38.6rem;
  height: 0;
  display: none;

  &.active {
    height: 5rem;
    display: block;
  }

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    border-radius: 1rem;
    border: 1px solid transparent;
    outline: none;
    padding-right: 5.6rem;
    padding-left: 1.6rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #000000;

    &.error {
      color: #EB4242;
      border: 1px solid #EB4242;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .unity-search-close {
    position: absolute;
    top: -2.8rem;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 2rem;
    border: none;
    outline: none;
    background-color: transparent;
    color: #ffffff;
    padding-left: 0;
    padding-right: 0;

    .icon-close {
      position: relative;
      top: 0;
      right: 0;
    }
  }

  .unity-search-btn {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
    //width: 2rem;
    //height: 2rem;
    //font-size: 1.9rem;
    border-radius: 50%;
    border: none;
    outline: none;
    padding-left: 0;
    padding-right: 0;
    background-color: white;

    &:disabled {
      cursor: no-drop;
    }

    .search-button {
      display: flex;
      background-color: rgb(0, 0, 0);
      width: 2rem;
      height: 2rem;

      &.error {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.unity-smile-button {
  display: flex;
  align-items: center;
  position: relative;

  &.active {
    .unity-smile-close {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 50%;
      border: none;
      outline: none;
      width: 1.6rem;
      height: 1.6rem;
      z-index: 11;

      .close-icon {
        mask-image: url("../../panda icons/closex.svg");
        width: 1.5rem;
        height: 1.5rem;
        background-color: black;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
      }

      &.active {
        top: 0;
        right: 0;
      }

      i:not(.close-icon) {
        display: block;
        transform: rotateZ(45deg);
        font-size: 0.8rem;
      }
    }

    .unity-smile-list {
      display: block;
      pointer-events: auto;
    }
  }
}

.unity-smile-selected {
  position: relative;
  display: flex;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  padding: 0.8rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;

  &.active {
    z-index: 2;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.unity-smile-close {
  display: none;
}

.unity-smile-list {
  position: absolute;
  pointer-events: none;
  width: 20.2rem;
  height: 20rem;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;

  .unity-smile-li {
    width: 4.2rem;
    height: 4.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    user-select: none;

    img {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;
    }

    &:nth-child(1) {
      top: 0;
      left: 8rem;
    }

    &:nth-child(2) {
      top: 2.6rem;
      left: 13.8rem;
    }

    &:nth-child(3) {
      top: 8.5rem;
      left: 16rem;
    }

    &:nth-child(4) {
      top: 14.3rem;
      left: 13.8rem;
    }

    &:nth-child(5) {
      top: 15.8rem;
      left: 8rem;
    }

    &:nth-child(6) {
      top: 14.3rem;
      left: 2.2rem;
    }

    &:nth-child(7) {
      top: 8.5rem;
      left: 0;
    }

    &:nth-child(8) {
      top: 2.6rem;
      left: 2.2rem;
    }
  }
}


//NFT Upload

.nft-upload-window {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.nft-upload-thumbnail-column {
  width: 41.8rem;
  flex: 0 0 auto;
  position: relative;

  button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 4.2rem;
    height: 4.2rem;
    padding: 0.8rem;
    border-radius: 50%;
    border: 0.2rem solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    z-index: 11;
  }

  .nft-upload-thumbnail-block {
    width: 100%;
    height: 45.9rem;
    display: block;

    input {
      display: none;
    }
  }
}

.nft-upload-thumbnail-placeholder {
  width: 100%;
  height: 100%;
  background-color: #E9E9E9;
  box-sizing: border-box;
  border-radius: 16px;

  .nft-upload-thumbnail-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 45rem;
      object-fit: cover;
      cursor: pointer;
      border-radius: 16px;
    }

    .icon-trash {
      position: absolute;
      cursor: pointer;
      top: 1rem;
      right: 1rem;
    }

    .upload-img {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8rem;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.nft-upload-btn {
  width: 100%;
  display: flex;
  justify-content: center;

  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-transform: uppercase;
    color: #EC7669;
  }
}

.nft-upload-form {
  width: 100%;
  flex: 1;
  padding-left: 2.4rem;
  box-sizing: border-box;
}

.nft-upload-form-field {
  padding-bottom: 3rem;
  position: relative;

  input {
    width: 100%;
    height: 5rem;
    border-radius: 0.8rem;
    border: 0.1rem solid #e9e9e9;
    box-sizing: border-box;
    outline: none;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  textarea {
    width: 100%;
    height: 9.2rem;
    resize: none;
    border-radius: 0.8rem;
    border: 0.1rem solid #e9e9e9;
    outline: none;
    box-sizing: border-box;
    padding: 1.6rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  &.creator_owner {
    display: flex;
    gap: 13px;
    padding: 0;

    div {
      width: 100%;
    }

    .user_roll {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
  }
}

.error_blok {
  position: relative;
  margin-top: 1rem;
}

.nft-upload-field-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #000000;
}

.nft-upload-form-double-block-cols {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nft-upload-dropdown {
  width: 20.8rem;
  height: 5rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #e9e9e9;
  margin-right: 2.4rem;
  position: relative;
  z-index: 1;

  p {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.1rem;
    cursor: pointer;

    i {
      position: absolute;
      font-size: 1.1rem;
      right: 2.2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ul {
    list-style: none;
    width: 100%;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.8rem;
    border: 0.1rem solid #e9e9e9;
    display: none;

    &.active {
      display: block;
    }

    li {
      font-size: 1.3rem;
      cursor: pointer;

      &:hover {
        background: grey;
        color: white;
      }
    }
  }
}


.modal-nft-info {
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 9;
}

.modal-nft-info-window {
  width: 100%;
  max-width: 86rem;
  margin: auto;
  background-color: #ffffff;
  padding: 3.2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal-nft-info-image {
  width: 41.8rem;
  height: 44.2rem;
  flex: 0 0 auto;
  border-radius: 0.7rem;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    border: 0.2rem solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
  }
}

.modal-nft-info-data {
  width: 100%;
  flex: 1;
  padding-left: 3.6rem;
  box-sizing: border-box;
}

.modal-nft-title {
  width: 100%;
  padding-top: 1.8rem;
  margin-bottom: 2.9rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.1rem;
  color: #000000;
}

.modal-nft-description-title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  color: #000000;
}

.modal-nft-description {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 1.8rem;
}

.modal-nft-price {
  .modal-nft-price-label {
    display: block;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #000000;
    margin-bottom: 0.4rem;
  }

  .modal-nft-price-text {
    display: block;
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: #000000;
  }
}

.modal-nft-links {
  width: 100%;
  margin-top: 2.1rem;
  list-style: none;
}

.modal-nft-link {
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;

  a {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0.1rem solid #E9E9E9;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border-radius: 0.6rem;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: rgba(0, 0, 0, 0.7);
  }

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
}

.nft-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  background: #0000008a;

  .nft-description {
    background: white;
  }

  img {
    width: 85%;
    height: 85%;
    object-fit: contain;
  }

  i {
    font-size: 30px;
    position: absolute;
    right: 25%;
    top: 5%;
    color: white;
    cursor: pointer;
  }
}

.nft-add-more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.8rem;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    span {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      background-color: #000000;
      color: #ffffff;
      font-size: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
    }
  }
}

.nft-upload-form-save {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.7rem 8.3rem;
  border: none;
  border-radius: 4.8rem;
  background-color: #000000;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-top: 4rem;
}

.nft-uploaded-block {
  width: 100%;
  height: 44.2rem;
  position: relative;
  border-radius: 0.8rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    border: 0.2rem solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
  }
}

.modal-delete-nft, .modal-confirm-nft {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 2;
}

.modal-delete-nft-window, .modal-confirm-nft-window {
  width: 100%;
  max-width: 48.2rem;
  margin: auto;
  background-color: #ffffff;
  border-radius: 1.6rem;
  overflow: hidden;
  position: relative;
}

.modal-delete-nft-title, .modal-confirm-nft-title {
  width: 100%;
  padding-top: 3.8rem;
  margin-bottom: 6rem;
  text-align: center;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 2.1rem;
  color: #000000;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
}

.modal-confirm-nft-title {
  margin-bottom: 0;
}

.modal-delete-nft-close, .modal-confirm-nft-close {
  position: absolute;
  top: 1.8rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  font-size: 2rem;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.modal-delete-nft-paragraph, .modal-confirm-nft-paragraph {
  width: 100%;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #000000;
  margin-bottom: 3.3rem;
}

.modal-nft-delete-buttons {
  display: flex;

  button {
    width: 100%;
    height: 7.6rem;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #000000;
    font-weight: 700;

    &:nth-child(2) {
      color: #EC7669;
    }
  }
}

.check-image-nft {
  width: 100%;
  padding-left: 18.4rem;

  img {
    width: 16.2rem;
    height: auto;
    display: block;
  }
}

.modal-nft-info {
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 9;
}

.modal-nft-info-window {
  width: 100%;
  max-width: 120rem;
  margin: auto;
  background-color: #ffffff;
  padding: 3.2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  i {
    font-size: 26px;
    cursor: pointer;
  }
}

.modal-nft-info-image {
  width: 41.8rem;
  height: 44.2rem;
  flex: 0 0 auto;
  border-radius: 0.7rem;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    border: 0.2rem solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
  }
}

.modal-nft-info-data {
  width: 100%;
  flex: 1;
  padding-left: 3.6rem;
  box-sizing: border-box;
}

.modal-nft-title {
  width: 100%;
  padding-top: 1.8rem;
  margin-bottom: 2.9rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.1rem;
  color: #000000;
}

.modal-nft-description-title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  color: #000000;
}

.modal-nft-description {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 1.8rem;
}

.modal-nft-price {
  .modal-nft-price-label {
    display: block;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #000000;
    margin-bottom: 0.4rem;
  }

  .modal-nft-price-text {
    display: block;
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: #000000;
  }
}

.modal-nft-links {
  width: 100%;
  margin-top: 2.1rem;
  list-style: none;
}

.modal-nft-link {
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;

  a {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0.1rem solid #E9E9E9;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border-radius: 0.6rem;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: rgba(0, 0, 0, 0.7);
  }

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
}


.quick-tweet-window {
  position: absolute;
  bottom: 8.1rem;
  background-color: #ffffff;
  border-radius: 1rem;
  width: 38.6rem;
  padding: 1.6rem 1.6rem 4rem;
  box-sizing: border-box;
  cursor: default;
  display: none;

  &.qatar {
    bottom: 6rem;
  }

  &.active {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.1rem;
    background-image: url("./../../images/tooltip-triangel.png");
    background-size: auto 100%;
    background-position: 50% 0;
    bottom: -0.9rem;
    z-index: -1;
  }

  &[data-align="center"] {
    left: 50%;
    transform: translateX(-50%);

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &[data-align="left"] {
    left: -1rem;

    &:after {
      left: 3.3rem;
    }
  }

  &[data-align="right"] {
    right: -1rem;

    &:after {
      right: 3.3rem;
    }
  }
}

.quick-tweet-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  color: #000000;
}

.quick-tweet-close {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  padding-left: 0;
  padding-right: 0;
  border: none;
  outline: none;
  background-color: transparent;

  .icon-close {
    position: relative;
    top: 0;
    right: 0;
  }
}

.quick-tweet-textarea {
  width: 100%;
  height: 10.2rem;
  resize: none;
  background: #f7f7f7;
  border: 0.1rem solid #e9e9e9;
  border-radius: 0.6rem;
  padding-top: 0.6rem;
  padding-right: 0.8rem;
  padding-left: 1.1rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #000000;
  box-sizing: border-box;
  outline: none;

  &.error {
    color: #EB4242;
    border-color: #EB4242;

    & + .quick-tweet-limit {
      color: rgba(235, 66, 66, 0.3);
    }
  }
}

.quick-tweet-limit {
  position: absolute;
  left: 1.8rem;
  bottom: 1rem;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.3);
}

.quick-tweet-to-public {
  position: absolute;
  right: 1.7rem;
  bottom: 1.4rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.6rem;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.2rem;
  color: #ec7669;
  text-transform: capitalize;
  font-weight: 600;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.2rem;
    background-color: #ec7669;
  }
}

.unity-board-title-edit {
  width: 38.6rem;
  height: 5rem;
  position: relative;
  margin-top: 1.8rem;
  border-radius: 1rem;

  .unity-board-title-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border: none;
    outline: none;
    font-size: 1.6rem;
    color: #ffffff;
    line-height: 2rem;
    text-align: center;

    &.active {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &.disable {
      pointer-events: none;
    }
  }

  .unity-board-title-edit-buttons {
    padding-bottom: 0.7rem;
    position: absolute;
    bottom: 75%;
    right: 1.1rem;
    display: none;

    &.active {
      display: block;
    }

    button {
      width: 2.2rem;
      height: 2.2rem;
      background-color: transparent;
      font-size: 2.2rem;
      border: none;
      outline: none;
      color: #ffffff;


      &:not(:first-child) {
        margin-left: 0.7rem;
      }
    }
  }
}

.unity-board-participants-title {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #ffffff;
  text-shadow: 0 0.2rem 1.1rem rgba(0, 0, 0, 0.5);

  span {
    color: #fff;
    margin-left: 5px;
    font-weight: 100;
    font-size: 17px;
    letter-spacing: 0;
  }
}

.unity-board-participants-list {
  width: 100%;
  max-height: 29.3rem;
  overflow: auto;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  transition: opacity 180ms;
  height: 0;
  opacity: 0;
  padding-right: 0.5rem;
  padding-top: 1.5rem;
  overflow-x: hidden;

  &.active {
    opacity: 1;
    height: 100%;
  }

  .muteBlok {
    display: flex;
    justify-content: flex-end;
  }

  .muteAll {
    color: white;
    gap: 10px;
    height: 23px;
    line-height: 23px;
    border: 0.5px solid #C4C4C4;
    border-radius: 56px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    user-select: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.unity-board-participants-close {
  font-size: 2rem;
  color: #ffffff;
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: transparent;
  border: none;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-close {
    position: relative;
    top: 0;
    right: 0;
  }
}

.unity-board-participant-avatar {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.unity-board-participant-name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4);
  width: 100%;

  span {
    margin-left: 5px;
  }
}

.unity-board-participants-volume {
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  background-color: transparent;
  border: 0.1rem solid #ffffff;
  box-sizing: border-box;
  background-image: url("../../images/microphone.svg");
  background-repeat: no-repeat;
  background-position: center;

  &.mute {
    background-image: url("../../images/microphone-off.svg");
    background-color: #EB4242;
    border-color: #EB4242;
  }
}

.unity-freeze {
  width: 38px;
  height: 38px;
  background-size: auto;
  background-repeat: no-repeat;
  border: 0;
  border: 1px solid white;
  background-position: center;
  background-image: url("../../images/unlock.svg");
  border-radius: 50%;
  background-color: transparent;

  &.mute {
    background-image: url("../../images/lock.svg");
    background-color: #EB4242;
  }
}

.main-carousel {
  display: grid;
  place-content: center;
  width: 100vw;
  height: 100vh;

  .slideshow {
    position: relative;
  }

  .slide {
    position: relative;
    display: none;
  }

  .slide-number {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1;
    font-size: 1.6rem;
    color: white;
  }

  .slide-image {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1000px;
  }

  .slide-image img {
    width: 100%;
  }

  .slide-image figcaption {
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    margin: 0 auto;
    text-align: center;
    color: white;
  }

  .slide.is-active {
    display: block;
  }

  .controls {
    position: absolute;
    top: calc(50% - 50px);
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
  }

  .controls button {
    padding: 16px;
    border: none;
    font-size: 1.8rem;
    color: white;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .controls button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .fade {
    animation-name: fade;
    animation-duration: 0.4s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

}

//UNITY BOARD -- End


// GOOGLE-LOGIN -- START

.google-login-wrapper {
  width: 100%;
  position: absolute;
  left: 0;

  iframe {
    display: none !important;
  }

  svg {
    display: none;
  }

  & > div > div > div > div {


    top: -2rem;
    border: none;
    height: 8rem;
    background-color: unset;
    color: transparent;

    &:hover, &:focus, &:active {
      background-color: transparent;
      border-color: unset;
    }

    & > :nth-child(1) {
      display: none;
    }
  }
}

// GOOGLE-LOGIN -- END

//POPUP----HOLDER----START

.popup-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: #00000066;
  padding: 20px;

  &.light {
    background: transparent;

    .popup-body {
      border-radius: 2rem;
      background: rgba(0, 0, 0, 0.7);
    }

    .popup-body-title, .popup-body-text, .pop-but {
      color: rgba(255, 255, 255, 1);
      max-width: 41rem;
    }
  }

  &.dark {
    background: rgba(0, 0, 0, 0.45);

    .popup-body {
      border-radius: 1.6rem;
      background: rgba(255, 255, 255, 1);
    }

    .popup-body-title, .popup-body-text, .pop-but, .popup-body-txt {
      color: rgba(0, 0, 0, 1);
      max-width: 36rem;
    }
  }
}

.popup-body {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  padding: 2.4rem 2rem;
  z-index: 99;
  background-color: white;
  border-radius: 16px;
  user-select: none;

  &.invite {
    &.invitation {
      padding: 2.8rem 2rem 0;
    }

    .popup-body-title {
      margin-bottom: 3rem;
    }

    .popup-body-txt {
      &.invitation {
        margin-top: 2.4rem;
        margin-bottom: 3.8rem;
      }
    }

    .popup-body-text {
      width: 100%;
      text-align: left;
    }
  }

  &.video-set, &.mute-all {
    max-width: 58.2rem;
  }

  &.video-set {
    .popup-body-title {
      margin-top: 0.4rem;
    }

    .popup-body-text {
      margin-top: 6.4rem;
    }
  }

  &.mute-all {
    .popup-body-title {
      margin-top: 4.8rem;
    }

    .popup-body-text {
      margin-top: 3rem;
    }
  }

  &.delete {
    max-width: 48rem;
    padding: 3.6rem 4.8rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-close {
      position: absolute;
      top: 1.8rem;
      right: 1.8rem;
      cursor: pointer;
    }

    .popup-body-title {
      font-size: 2.8rem;
      line-height: 3.1rem;
      text-transform: unset;
    }

    .popup-body-text {
      padding: 2rem 0;
    }
  }

  &.banner {
    width: unset;
    height: unset;
    padding: 5.1rem 3.2rem 2rem;
  }

  &.leave, &.control, &.clear, &.save, &.remove, &.deactivate, &.invite, &.private {
    max-width: 48rem;
    min-height: 27rem;
    padding: 4.4rem 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 4rem);

    .icon-close {
      position: absolute;
      top: 1.8rem;
      right: 1.8rem;
      cursor: pointer;
    }

    .popup-buttons {
      width: 100%;
      justify-content: space-around;
      margin: 0;
      padding: 2.8rem 0 0.4rem 0;
    }

    .pop-but {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      color: rgba(0, 0, 0, 1);
    }

    .popup-body-title {
      font-size: 2.8rem;
      line-height: 2.1rem;
      text-transform: unset;
    }

    .popup-body-text {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 700;
    }
  }

  &.remove {
    padding: 4rem 6rem 2rem;
    min-height: unset
  }

  &.deactivate {
    padding: 4rem 8rem 3.3rem;

    .popup-body-text {
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.4rem;
      padding: 4.5rem 0 1.5rem;
    }

    .pop-but {
      min-width: unset;
    }
  }

  &.leave:before, &.clear:before, &.save:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 0.1rem;
    left: 0;
    bottom: 7.6rem;
    background: #F3F3F3;
  }

  .popup-input-container {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    .popup-input {
      margin-top: 0;
    }

    .add-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      color: #EC7669;
      cursor: pointer;
      border: none;
      background-color: transparent;
      font-size: 1.6rem;
      line-height: 2rem;

      &.error {
        top: calc(50% - 2rem);
        transform: translateY(calc(-50% + 1rem));
      }
    }
  }

  .invited-users {
    width: 100%;
    overflow-y: auto;
    max-height: 135px;
    margin-top: 10px;

    .invited-user {
      position: relative;
      font-size: 1.4rem;
      line-height: 2rem;
      padding-top: 1rem;
      padding-bottom: 1.4rem;
      padding-left: 5px;
      border-bottom: 1px solid #F2F2F2;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: rgba(225, 225, 225, 0.71);
      }

      span {
        width: calc(100% - 2rem);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon-close {
        background-color: black;
        top: 50%;
        transform: translateY(-50%);
        right: 2%;
      }
    }
  }
}

.persons-block {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 5.4rem;
  border-radius: 2.8rem;
  background: rgba(#D6D6D6, 0.8);
  padding: 0.5rem 1.7rem 0.5rem 0.7rem;
}

.person-items {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.2rem;
  height: 4.2rem;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &:not(:first-child) {
    margin-left: -2rem;
  }

  @for $i from 50 through 1 {
    &:nth-child(#{$i}) {
      z-index: 50 - $i;
    }
  }
}

.count-item {
  position: absolute;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0.6rem;
  right: 0.7rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  padding: 0.4rem 0.5rem;
  background: rgba(0, 0, 0, 1);
  border-radius: 2.2rem;
  z-index: 99;
}

.popup-body-el {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.popup-body-title {
  text-transform: capitalize;
  word-break: break-word;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
}

.popup-body-text {
  font-weight: 600;
  word-break: break-word;
  text-align: center;
  font-size: 2rem;
  line-height: 2.4rem;
  width: 100%;
}

.popup-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 5.4rem;
  padding-bottom: 3.6rem;
}

.pop-but {
  display: flex;
  align-items: center;
  padding: 0.7rem 2rem;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 2.4rem;
  border-radius: 2rem;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
  background: transparent;
  transition: 240ms;
  border: none;
  outline: none;

  &:not(:last-child) {
    margin-right: 3rem;
  }

  &.mute {
    box-shadow: unset;
    background: #EB4242;
  }

  i {
    font-size: 1.9rem;
    line-height: 2.4rem;
    margin-right: 0.6rem;
  }

  &.leave, &.clear, &.save, &.send {
    color: rgba(236, 118, 105, 1) !important;
  }

  &.banner {
    background: black;
    color: white;
    padding: 1.7rem 8rem;
    border-radius: 6.6rem;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.tutorial-text {
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-top: 4rem;
  font-weight: 700;
  color: #EC7669;
  border-bottom: 1px solid #EC7669;
  cursor: pointer;
}

.controls-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 6rem;

  &.bottom {
    padding-bottom: 4.2rem;

    .controls-item-text {
      font-size: 1.4rem;
    }
  }
}

.controls-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    max-width: 4.8rem;
    height: auto;
    display: block;
  }
}

.controls-item-text {
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 2.6rem;
}

.move-box {
  width: 3.2rem;
  height: 3.2rem;
  background: rgba(0, 0, 0, 1);
  border-radius: 0.3rem;
}

.controls-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.controls-box-item-top {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.controls-box-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 1);
  border-radius: 0.3rem;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }

  &.sp {
    min-width: 7rem;
  }
}

.popup-body-txt {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  padding: 0.8rem 0;
  text-align: center;

  &.delete {
    text-align: start;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.pop-input-wrap {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 2rem;
}

.popup-input-label {
  width: 100%;
  text-align: left;
  padding-top: 6.4rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}

.popup-input {
  width: 100%;
  height: 4.6rem;
  border-radius: 0.6rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: #000000;
  border: 0.1rem solid #E9E9E9;
  outline: none;
  margin-top: 0.8rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;

  &.email {
    padding-right: 5.2rem;
  }

  &.empty {
    border-color: red;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
}

.double-login-modal {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 11111;

  .double-login-popup {
    position: relative;
    background: #FFFFFF;
    border-radius: 12px;
    height: 188px;
    width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    .ok {
      color: #EC7669;
      font-size: 20px;
      cursor: pointer;
    }

    .double-login-description {
      padding: 30px;
    }
  }
}

.page-content {
  overflow: hidden;
  height: 100vh;

  .expired-link-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    padding: 150px 0;

    .jetex-logo {
      height: 9.6rem;
    }

    .expired-link-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;

      .page-not-found {
        width: 340px;
        object-fit: contain;
        margin-bottom: 15px;
      }

      .expired-page-scrap {
        width: 150px;
        filter: invert(1);
        margin-bottom: 100px;
      }

      .maintenance-page-scrap {
        width: 600px;
        color: white;
        margin-bottom: 100px;
      }

      h3 {
        margin-top: 20px;
        top: 15px;
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        color: white;
      }

      .expired-content, .page-not-found-content {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 30px;
        text-align: center;
        padding: 5px 20px;
        color: white;
      }

      .expired-content-or {
        margin: 42px 0 30px;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        color: white;
      }

      .page-not-found-content {
        max-width: 42rem;
        color: white;
      }

      .expired-link-btn {
        color: white;
        cursor: pointer;
        display: block;
        font-size: 16px;
        position: relative;
        width: 174px;
        height: 58px;
        background-color: #FF6900;
        border: 1px solid transparent;
        outline: 1px solid transparent;
        outline-offset: 0;
        text-shadow: none;
        -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        outline-color: grey;
        border-radius: 252px;

        &.guest {
          font-weight: 800;
          font-size: 21px;
        }

        &.login {
          background-color: transparent;
          width: 114px;
          height: 46px;
        }

        &:hover {
          border: 1px solid;
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
          outline-offset: 15px;
          outline-color: rgba(255, 255, 255, 0);
        }
      }
    }
  }
}

.popup-map-container {
  position: absolute;
  top: 50px;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-map-btn {
    background-image: url("../../images/location.png");
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  }
}


.iframe-popup {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000061;

  .popup-iframe {
    width: 80vw;
    height: 80vh;
    margin: auto;
  }

  .popup-close {
    position: absolute;
    top: 6rem;
    right: 16rem;
    width: 40px;
    height: 40px;
    background: transparent;
    font-size: 30px;
    border: none;
  }

}

@media only screen and (max-width: 979px) {
  .root-footer-btn-container.send-again {
    margin-top: 12.8rem;
  }
  .fastex_panel {
    &.active {
      display: none;
    }
  }
  .p-mr-modal {
    max-height: 100vh;
    padding: 1.5rem;
  }
  .nft-upload-window {
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    overflow-y: scroll;

    .nft-upload-form-save {
      margin: 4rem auto 0;
    }
  }
  .audio-video-popup {
    max-width: 55rem !important;
    height: 60rem !important;
    position: relative !important;
  }
  .join-btn {
    width: 9.5rem;
    height: 3.5rem;
    font-size: 1rem;
  }
  .unity-more-button-menu {
    width: 18.5rem;
    padding: 15px;
  }
  .unity-more-button-menu-el {
    font-size: 1rem;
    margin-bottom: 1rem;

    i {
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
    }
  }
  .p-mid-btn {
    width: 3.2rem;
    height: 3.2rem;
  }
  .continue-btn {
    font-size: 1rem;
  }
  .audio-vid-popup-mid .video-player, .audio-vid-popup-mid .video-img {
    width: 240px !important;
    height: 240px !important;
  }

  .participants-wrp {
    padding: 3.2rem 4rem;
  }
  .p-big-btn {
    width: 4.5rem;
    height: 4.5rem;
  }
  .p-extra-big-btn {
    width: 6rem;
    height: 6rem;
  }
  .participants-popup-wrp {
    background-color: rgba(#000000, 0.4);
    z-index: 99;
  }
  .new-space-switcher {
    max-width: 40rem !important;
  }
  .new-space-el-list {
    grid-template-columns: 12fr !important;
    max-width: 56rem !important;
  }
  .p-mr-modal-user-profile-avatar {
    width: 50%;
  }

  .p-mr-modal-user-profile-back i {
    font-size: 2rem;
  }

  .p-mr-modal-user-profile-form {
    justify-content: space-around;
  }

  .root-page-description.help-text, .root-page-description {
    font-size: 1rem;
  }
  .root-page-password-field .root-body-pass-field {
    padding-right: 0;
    text-align: center;
  }

  .p-mr-modal-user-profile-email {
    padding: 20px;
  }
  .p-mr-modal-form-btn {
    min-width: 11rem;
    height: 4rem;
    margin: 0 auto;

    span {
      font-size: 1.1rem;
    }
  }
  .p-mr-modal-form-label {
    width: 100%;
    margin-bottom: 1rem;

    span {
      font-size: 1.8rem;
      line-height: 1;
      margin-bottom: 1rem;
    }

    input {
      font-size: 2rem;
      padding: 0 1rem;

      &::-webkit-input-placeholder {
        font-size: 2rem;
      }
    }
  }
  .p-mr-modal-change-avatar {
    right: -6.5rem;

    img {
      width: 6rem;
    }
  }

  .p-mr-modal-tabs {
    grid-area: 2 / 1 / 3 / 6;
  }

  .p-mr-modal-action-holder {
    grid-area: 2 / 1 / 3 / 4;
    max-width: 100%;
    margin-left: 0;
    padding-right: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-size-adjust: none;
  -webkit-text-fill-color: #FFFFFF;
  caret-color: #FFFFFF;
  &::selection {
    -webkit-text-fill-color: #000000;
  }
}

@media only screen and (max-width: 624px) {
  .p-mid-btn {
    width: 3.4rem;
    height: 3.4rem;
    margin-right: 0.4rem;
  }

  .p-mr-modal-change-avatar {
    display: none;
  }

  .root-page-password-field .root-page-pass-actions {
    right: -15px;
  }

  .root-page-check {
    font-size: 1rem;
  }

  .root-body-input {
    font-size: 1.4rem;
  }

  .slider-item {
    width: 100%;
  }

  .root-footer-btn {
    width: 50%;
    font-size: 1rem;
  }

  .participants-btn {

    .arrow-icon {
      font-size: 1.4rem;
    }
  }
  .participants-top-cont {
    padding: 0 7rem;
  }
  .participants-bottom-cont {
    padding: 0 9rem;
  }
  .p-big-btn {
    width: 4rem;
    height: 4rem;
  }
  .p-extra-big-btn {
    width: 5.5rem;
    height: 5.5rem;
  }
  .audio-video-popup {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 2.2rem 1.2rem;
  }
  .audio-vid-p-bottom-box {
    padding: 0 6rem;
  }
  .p-mr-modal-user-profile-inside {
    flex-direction: column;
    align-items: center;

    .p-mr-modal-user-profile-avatar {
      height: 55%;
      width: 100%;

      .avatar-img {
        height: calc(100% - 4rem);
        object-fit: contain;
        object-position: center;
        bottom: 0;
        top: unset;
      }

      .edit_profile {
        top: 16px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .p-mr-modal-user-profile-email {
      width: 100%;
    }
  }

  //.p-mr-modal-add {
  //  display: none;
  //}

  .space-loader {
    .space-container {
      h1 {
        font-size: 18px;
      }

      .space-list {
        font-size: 14px;
        margin-top: 5px;
      }

      //width: 65vw;
      //height: 65vw;
    }
  }
  .expired-link-page {
    .expired-link-container {

      .expired-page-scrap {
        width: 100px;
        height: 100px;
      }
    }
  }
  .buy-nft-inner-container {
    .buy-nft-outer-container {
      flex-direction: column;

      .buy-nft-img {
        height: 17.8rem;
        width: 30.6rem;
        object-fit: cover;
        margin-bottom: 1.6rem;
      }

      .buy-nft-desc-container {
        width: 100% !important;

        .nft-users-container {
          flex-direction: column;
        }

        .buy-nft-description-container, .buy-nft-btn, .nft-user-container {
          width: 100% !important;
        }
      }

      .nft-title {
        display: none;

        &.mobile {
          display: flex !important;
          margin: 1.8rem 0 0 0 !important;
          //TODO get other solution
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .root-footer-btn-container.send-again {
    margin-top: 6rem;
    width: 100%;
  }
  .p-mr-event-input-holder {
    input {
      font-size: 2.4rem;
    }

    .Like-ico {
      width: 3rem;
      height: 3rem;
    }
  }
  .p-mr-modal-holder {
    background-position: 50%;
    margin-bottom: 1.6rem;

    .userSettings {
      gap: 5px;
    }

    .p-mr-modal-logo {
      width: 38px;
    }

    .p-mr-modal-search-btn {
      .icon-search {
        width: 16px;
        height: 16px;
      }
    }

    .p-mr-modal-sort {
      .p-mr-modal-sort-svg-btn {
        width: 20px;
        height: 20px;
      }
    }

    .DraftIcon {
      width: 16px;
      height: 16px;
    }

    .p-mr-modal-action .icons {
      width: 16px;
      height: 16px;
    }

    .translation-container .lang-btn svg:first-child {
      width: 16px;
      height: 16px;
    }

    .translation-container .lang-btn svg:nth-child(2) {
      width: 8px;
    }

    .fastex_panel {
      gap: 5px;
      font-size: 10px;
      padding: 8px;

      &.active {
        display: none;
      }
    }
  }
  .p-mr-modal-close {
    top: -1rem;
    right: -1rem;
    width: 3.2rem;
    height: 3.2rem;
    background-color: #ffffff;

    i {
      font-size: 2.6rem;
    }
  }
  .p-mr-modal-user {
    .p-mr-modal-popover {
      &.open {
        top: 3rem;
      }
    }
  }
  .p-mr-modal-categories {
    .p-mr-modal-popover {
      &.open {
        top: 3rem;
        right: -14rem;
      }
    }
  }

  .p-mr-modal-add-btn {
    width: 4rem;
    height: 4rem;
  }
  .p-mr-modal {
    max-height: 100vh;
    padding: 1.5rem;
  }

  .p-mr-event-title {
    display: flex;
    flex-direction: column;
  }

  .popup-body {
    &.leave, &.control, &.clear, &.save, &.remove, &.deactivate, &.invite, &.private {
      padding: 2rem;
      min-height: 21rem;

      .popup-body-title {
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 700;
      }

      .popup-body-text {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 700;
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 539px) {
  .participants-wrp {
    padding: 3.2rem 2rem;
  }
  .participants-btn {
    .arrow-icon {
      font-size: 1.2rem;
    }
  }
  .p-mid-btn {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.1rem;
  }
  .participants-bottom-cont {
    padding: 0 6rem;
  }
  .p-big-btn {
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 0.4rem;
  }
  .p-extra-big-btn {
    width: 3.6rem;
    height: 3.6rem;
    margin: 0 0.6rem 0 0.2rem;
  }

  .new-space-switcher {
    height: 3rem !important;
    max-width: 35rem !important;
    margin-bottom: 30px !important;
    width: 67% !important;

    .back {
      top: 2rem !important;
      right: 2.5rem !important;
    }
  }

  //.new-space-el-info-col {
  //  width: 22vw !important;
  //}

  .new-space-el-title {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 450px) {
  .panda-my-space-list {
    .card-container {
      &.integrations {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  .p-mr-modal-header {
    grid-template-rows: 3fr 1fr;
  }
  .p-mr-modal-tabs {

    .p-mr-modal-tab-txt {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  .count-down-container .count-down-title {
    padding-bottom: 0 !important;
  }

  .p-mr-modal-sort-btn {
    min-width: 8rem;
    max-width: 9rem;
    height: 2.5rem;

    span {
      font-size: 1rem;
    }
  }
  .new-space-el-info-col {
    padding: 15px !important;
    width: 12.9rem !important;
  }
  .new-space-el {
    height: 18rem !important;
  }

  .new-space-el-thumbnail {
    width: 5rem;
    height: 5rem;
  }

  .unity-board-user-list-block {
    width: 40%;
  }

  .right-container {
    .title {
      font-size: 22px !important;
    }

    .description {
      font-size: 15px !important;
      margin-bottom: 5px !important;
    }

    .category {
      margin: 10px !important;
    }
  }


}

@media only screen and (max-width: 300px) {
  .participants-wrp {
    padding: 2.2rem 1rem;
  }
  .p-mid-btn {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0.1rem;
  }
  .participants-bottom-cont {
    padding: 0 5rem;
  }
  .p-big-btn {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.4rem;
  }
  .p-extra-big-btn {
    width: 3.2rem;
    height: 3.2rem;
    margin: 0 0.6rem 0 0.2rem;
  }
  .new-space-switcher {
    max-width: 22rem !important;
  }
}

.empty-state {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    height: 112px;
    transform: translateX(35px);
    margin-bottom: 25px;
  }
  span {
    font-size: 25px;
    width: 420px;
    text-align: center;
    color: #FFFFFF;
  }
}

.p-mr-modal-categories {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  .category-items {
    width: 150px;
  }

  path {
    fill: white;
    stroke: black;
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: none;
    text-align: left;
    text-decoration: none;
    padding: 5px 0;
    width: 100%;

    &.selected {
      font-weight: bold;
    }
  }
}

.sort-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  align-items: center;

  .category-name {
    color: white;
    font-size: 20px;
  }
}

.Like-ico {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  stroke: white;
  stroke-width: 2px;

  path {
    fill: white;
  }

  .content {
    fill: none;
  }

  &.like {
    .content {
      fill: white;
    }
  }

  &.black {
    stroke: black;

    .content {
      fill: none;
    }

    &.like {
      .content {
        fill: black;
      }
    }
  }

  &.small {
    cursor: auto;
    width: 16px;
    height: 16px;
    filter: drop-shadow(1px 1px 2px black);
  }
}

.like-panel {
  display: flex;
  align-items: center;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  border-radius: 45px;
  padding: 0.7rem 2.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 1.2rem;

  .Like-ico {
    margin-top: 0;
  }

  .like-num {
    color: white;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-left: 1rem;
  }
}


.look-amazing {
  text-align: center;
  color: white;
  font-size: 2rem;
  margin-top: 2rem;
}

.icon-close {
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 2rem;
  height: 2rem;
  mask: url("../../panda icons/close.svg") no-repeat;
  mask-size: contain;
  background-color: white;
  cursor: pointer;
}

.icon-plus {
  font-size: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("../../panda icons/plus.svg");
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-trash-can {
  mask-image: url("../../panda icons/delete.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  height: inherit;
  width: inherit;
}

.icon-upload {
  display: flex;
  width: 24px;
  height: 24px;
  mask-image: url("../../panda icons/upload.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  margin-right: 1rem;
}

.icon-trash {
  mask-image: url("../../panda icons/delete.svg");
  width: 2.4rem;
  height: 2.4rem;
  background-color: #fff;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.icon-lock {
  mask-image: url("../../panda icons/Unlock.svg");
  width: 2.4rem;
  height: 2.4rem;
  background-color: #fff;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.icon-link {
  width: 2rem;
  height: 2rem;
  mask: url("../../panda icons/link.svg") no-repeat;
  mask-size: contain;
  background-color: #000;
}

.icon-download {
  width: 2.4rem;
  height: 2.4rem;
  mask: url("../../panda icons/Download.svg") no-repeat;
  mask-size: contain;
  background-color: #fff;
}

.icon-edit {
  width: 2rem;
  height: 2rem;
  mask: url("../../panda icons/edit.svg") no-repeat;
  mask-size: contain;
  background-color: #fff;
}

.icon-more {
  width: 2.4rem;
  height: 2.4rem;
  mask: url("../../panda icons/more.svg") no-repeat;
  mask-size: contain;
  background-color: #fff;
}

.icon-back {
  width: 2rem;
  height: 2rem;
  mask: url("../../panda icons/back.svg") no-repeat;
  mask-size: contain;
  background-color: #000;
}

.icon-rounded-arrow {
  width: 1.8rem;
  height: 0.8rem;
  mask: url("../../panda icons/icon-rounded-arrow.svg") no-repeat;
  mask-size: contain;
  background-color: #000;
}

.icon-check {
  width: 2rem;
  height: 1rem;
  mask: url("../../icons/check.svg") no-repeat;
  mask-size: contain;
  background-color: #000;
}

.icon-arrow {
  width: 2rem;
  height: 1rem;
  mask: url("../../icons/arrow.svg") no-repeat;
  mask-size: contain;
  background-color: #000;
}

.icon-pro {
  width: 1.8rem;
  height: 1.8rem;
  mask: url("../../icons/pro.svg") no-repeat;
  mask-size: contain;
}

.icon-participant {
  width: 1.8rem;
  height: 1.8rem;
  mask: url("../../icons/profile.svg") no-repeat;
  mask-size: contain;
  background-color: black;
}

.icon-pass-eye {
  width: 3.4rem;
  height: 3.4rem;
  mask: url("../../icons/pass-eye.svg") no-repeat;
  mask-size: auto;
  background-color: black;
}

.icon-play-stroked {
  width: 2.4rem;
  height: 2.4rem;
  mask: url("../../icons/icon-play-stroked.svg") no-repeat;
  mask-size: contain;
  background-color: black;
}

.logout-img {
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("../../../assets/panda icons/leave.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  border-radius: 45px;
}

.icon-volume-on {
  width: 2rem;
  height: 1.5rem;
  background-image: url("../../../assets/icons/icon-volume-on.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}

.icon-image {
  width: 2rem;
  height: 1.5rem;
  background-image: url("../../../assets/icons/icon-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  border: none;
}

.icon-search {
  width: 2rem;
  height: 2rem;
  display: flex;
  mask-image: url("../../../assets/icons/search.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: black;
  border: none;
}


@media screen and (max-height: 845px) {
  .outside-logo {
    display: none
  }
}

.joined-Members {
  width: 319px;
  max-height: 229px;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 47%) 100%);
  border-radius: 10px;
  right: 123px;
  bottom: 0;
  margin-bottom: 120px;
  z-index: 2;
  overflow: hidden;

  .member_item {
    font-family: "Mont";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    color: white;
    margin: 22px;
    bottom: 0px;
    position: relative;
    transition: all 3s;
    opacity: 1;

    &.hide {
      opacity: 0;
      bottom: 200px;
    }
  }

}

.translation-container {
  position: relative;

  .lang-btn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg:first-child {
      width: 2.4rem;
      height: 2.4rem;
    }

    .stroke {
      transition: 0.2s;
      margin-left: 5px;
    }

    &.active {
      .stroke {
        transform: rotate(180deg);
        transition: 0.2s;
      }
    }
  }

  .translation-navbar {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    list-style-type: none;
    background: #FFFFFF;
    box-shadow: 0 0.5rem 1.2rem rgba(90, 95, 105, 0.2);
    border-radius: 0.8rem;
    position: absolute;
    z-index: 1111;
    top: 3rem;
    left: -7rem;

    &.active {
      display: flex;
    }

    li {
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: rgba(128, 128, 128, 0.22)
      }

      span {
        font-size: 1.3rem;
        font-weight: bold;
        padding-left: 1.4rem;

        .active {
          font-weight: bolder;
        }
      }

      svg {
        width: 2.5rem;
        height: 2rem;
      }
    }
  }
}

.buy-nft-inner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  padding: 3.2rem;
  z-index: 99;
  background-color: white;
  border-radius: 1.6rem;
  user-select: none;
  min-height: 50rem;
  display: flex;
  justify-content: center;
  width: 90vw;
  height: 90vh;

  &:has(.buy-nft-iframe) {
    padding: 0;
  }

  .buy-nft-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 1.6rem;
    overflow: hidden;
  }

  .buy-nft-outer-container {
    display: flex;
    width: 90%;

    .buy-nft-img {
      border-radius: 1.2rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 41.8rem;
      max-height: 23rem;
      width: 49%;
      max-height: 80vh;
      object-fit: contain;
      margin-right: 1.8rem;

      .nft_info{
        overflow: auto;
      }
    }

    .buy-nft-desc-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 49%;

      .nft-users-container {
        display: flex;
      }

      .nft-user-container {
        display: flex;
        flex-direction: row;
        width: 50%;
        align-items: center
      }

      .nft-title {
        font-weight: 800;
        font-size: 2.4rem;
        line-height: 2.1rem;
        color: rgb(0, 0, 0);
        margin: 2.6rem 0;

        &.mobile {
          display: none;
        }
      }

      .nft-user-position {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: rgba(0, 0, 0, 0.7)
      }

      .nft-user-name {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: rgb(0, 0, 0)
      }

      .nft-user-image {
        width: 4.25rem;
        height: 4.25rem;
        border-radius: 3.85rem;
        margin-right: 0.5rem;
      }

      .buy-nft-description-container {
        margin: 2.6rem 0;
        width: 75%;

        .buy-nft-description-title {
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2rem;
          color: rgb(0, 0, 0);
          margin-bottom: 1rem;
        }

        .buy-nft-description-text {
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: rgba(0, 0, 0, 0.7);
        }
      }

      .buy-nft-btn {
        border-radius: 5.6rem;
        background: #000000;
        border: none;
        font-size: 1.6rem;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 1.5rem;
        cursor: pointer;
        padding: 1.5rem 10rem;
        width: 75%;

        &.disabled {
          background: #00000063;
        }
      }
    }
  }
}

.gift-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6rem;

  &:first-child, &:nth-child(2) {
    margin-top: 3rem;
  }

  .gift-image-container {
    width: 9rem;
    height: inherit;

    .gift-image {
      width: inherit;
      height: inherit;
      object-fit: cover;
      border-radius: 0.6rem;
    }
  }

  .gift-desc-container {
    background: rgba(0, 134, 255, 0.07);
    border-radius: 0.6rem;
    height: inherit;
    width: calc(100% - 10rem);
    margin-left: 0.6rem;
    display: flex;
    align-items: center;
    padding: 0 1.8rem;

    .gift-desc {
      font-family: 'Mont';
      font-style: italic;
      font-weight: 600;
      font-size: 1.1rem;
      line-height: 1.2rem;
      color: #0086FF;
      width: calc(100% - 2rem);
      word-wrap: anywhere;
      padding-right: 2.5rem;
      text-decoration: none;
    }

    .gift-link-container {
      display: flex;
      justify-content: flex-end;
      width: 2.4rem;
      height: 2.4rem;
      position: relative;

      .icon-link {
        background-color: #0086FF;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
      }

      .tooltiptext {
        display: none;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        right: -1.5rem;
        top: -3rem;

        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: 1;

        &.active {
          display: block;
          visibility: visible;
          opacity: 1;
          transition: visibility 0s 3s, opacity 3s linear;
        }

        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #555 transparent transparent transparent;
        }
      }
    }
  }
}

.wheel-popup {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-iframe {
    width: 484px;
    height: 586px;
    border: none;
    border-radius: 40px;
  }
}

.count-down-outer-container {
  position: absolute;
  right: 6rem;
  top: 11.5rem;
  z-index: 1;
}

.count-down-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #FF0080;
  border-radius: 1.3rem;
  align-items: center;
  width: 32rem;
  margin: 0 auto 1.8rem;

  .count-down-elements {
    display: flex;
    flex-direction: column;
    margin: 0.55rem 2.25rem;
  }

  .count-down-elements-container {
    display: flex;
    justify-content: center;
  }

  .count-down-value, .count-down-name {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: #FF0080;
  }

  .count-down-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    color: #000000;
    padding: 0.7rem 3.35rem 0;
  }

  &.black {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    z-index: 1;
    align-items: unset;

    &.winners {
      padding-bottom: 10px;
    }

    .count-down-value {
      color: #EBEBEB;
    }

    .count-down-title {
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #EBEBEB;
      border-bottom: 1px solid #E5F96F;
      padding: 0.7rem 3.35rem;
    }

    .count-down-name {
      color: rgba(255, 255, 255, 0.48);
      font-size: 0.8rem;
      line-height: 1.2rem;
    }

    .count-down-user {
      color: white;
      font-size: 1.4rem;
      line-height: 2.1rem;
      padding: 0.6rem 1rem;
    }
  }

  &.hide {
    background: transparent;
    border: none;
  }
}

.delete-checkbox-imitation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #F1F1F1;
  border: 1px solid #C4C8CC;
  cursor: pointer;

  &.active {
    border: 0.1rem solid rgba(var(--mirror-color), 1);
    background-color: transparent;
  }

  input {
    display: none;

    &:checked + span {
      position: relative;
      width: 1.2rem;
      height: 1.2rem;
      border: none;
      background-color: black;
      border-radius: 50%;
    }
  }
}

.p-mr-modal-delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  width: 12.5rem;
  border: none;
  border-radius: 4.8rem;
  background-color: #000000;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-top: 2rem;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: auto;
  }
}

.success-icon-back {
  background-color: #E5F96F;
  width: 8.8rem;
  height: 8.8rem;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .accept {
    width: 5rem;
    height: 5rem;
    background-image: url("../../panda icons/icon-accept.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .reject {
    width: 4rem;
    height: 4rem;
    background-image: url("../../panda icons/icon-reject.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}


.request-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8.8rem;
  width: 8.8rem;
  border-radius: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);

  .wave1 {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
    border: 1px solid rgba(0, 0, 0, 1);
  }

  .wave2 {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 1.25s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
    border: 1px solid rgba(0, 0, 0, 1);
  }

  .wave3 {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
    border: 1px solid rgba(0, 0, 0, 1);
  }

  .wave4 {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
    border: 1px solid rgba(0, 0, 0, 1);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
    border: 1px solid rgba(0, 0, 0, 0.02);
  }
}

.icon-locker {
  width: 5rem;
  height: 5rem;
  background-image: url("../../panda icons/lock.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.locked-room {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.45);
  border-radius: 1.3rem;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-container {
  background-color: rgba(0, 0, 0, .7);
  border: none;
  border-radius: 20px;
  height: 80vh;
  padding: 4rem;
  position: absolute;
  transform: translate(22vw, 10vh);
  width: 56vw;
  z-index: 100;

  .icon-close {
    background-color: white;
  }
}


.games-container {
  width: 90vw;
  height: 90vh;
  transform: translate(5vw, 5vh);
  position: absolute;
  z-index: 100;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 4rem;

  .icon-close {
    background-color: white;
  }
}

.games-iframe {
  width: 100%;
  height: 100%;
  background-color: black;
  border: none;
  border-radius: 20px;
  overflow-y: auto;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.banner-img {
  height: 44rem;
  width: auto;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.p-mr-inventory-popup {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 109rem;
  max-height: 72rem;
  padding: 6rem 3.2rem 5.2rem;
  border-radius: 1.6rem;
  background: #1C1A1AF7;
  backdrop-filter: blur(6px);
  &:has(.add_btn_container) {
    padding: 6rem 3.2rem 7.2rem;
  }

  .add_btn_container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .add_to_scene{
      width: 206px;
      height: 50px;
      color: white;
      background: #FF6900;
      border-radius: 43px;
      border: none;
      transition: background-color 0.3s;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
      &.disabled{
        background: #FF690087;
        cursor: not-allowed;
      }
    }
  }
}

.ecommerce-wrapper {
  display: grid;
  height: 95%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 45%;
  gap: 20px;
  padding: 1rem;

  &.empty {
    height: 100%;
  }

  .empty-shop {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 44.7rem;

    img {
      width: 15.9rem;
      height: 11.2rem;
      object-fit: cover;
      transform: translateX(35px);
    }

    p {
      text-align: center;
      font-size: 2.4rem;
      line-height: 3.6rem;
      margin: 2rem;
      color: #FFFFFF;
      font-weight: 300;
    }

    a {
      width: 18.4rem;
      height: 4.8rem;
      background: #FF6900;
      border-radius: 4.8rem;
      border: none;
      font-size: 1.6rem;
      font-weight: 400;
      text-decoration: none;
      text-align: center;
      line-height: 5rem;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;

      span {
        margin-right: 10px;
        font-size: 25px;
        display: inline-block;
      }
    }
  }

  .e-commerce-card {
    cursor: pointer;
    position: relative;
    border-radius: 1.5rem;
    overflow: hidden;
    transition: 0.2s all;
    border: 1px solid #00000087;

    &:hover {
      transform: scale(1.05);
    }

    .active-border {
      display: none;
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/icons/ticksquare.svg");
      background-size: 5.5rem 5.5rem;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 4;
      background-color: #00000087;
      top: 0;
      border: 1px solid #FFFFFF87;
      border-radius: 1.5rem;
    }

    &.active {
      .active-border {
        display: inline-block;
      }
    }
    .img-bg {
      width: calc(100% - 4rem);
      height: calc(100% - 8.7rem);
      background-color: #e7e7e7;
      margin: 2rem;
      border-radius: 0.7rem;

      img {
        width: calc(100% - 4rem);
        height: inherit;
        position: absolute;
        z-index: 2;
        object-fit: cover;
        border-radius: 0.7rem;
      }
    }

    h3 {
      position: absolute;
      z-index: 3;
      color: white;
      bottom: 15px;
      left: 15px;
      font-size: 17px;
      width: calc(100% - 4rem);
      text-align: center;
    }
  }
}

.icon-touch-controller {
  position: absolute;
  display: flex;
  width: 38px;
  height: 20px;
  background: #000000;
  border-radius: 11.5px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .icon-active-touch {
    background-color: #FFFFFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 2px;
    transition: all 0.2s;

    &.active {
      margin-left: 20px;
      background-color: #E5F96F;
    }
  }
}

.terms-outer-body {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);

  .terms-inner-body {
    margin: 89px 57px;
    border-radius: 15px;
    padding: 30px;
    overflow-y: auto;
    background-color: #0F0F0F;
    position: relative;

    .icon-close {
      position: fixed;
      top: 109px;
      right: 77px;
      display: flex;
      align-items: center;
      text-decoration: none;
      width: 3rem;
      height: 3rem;
      -webkit-mask: url("../../../assets/icons/close_cicle.svg") no-repeat;
      mask: url("../../../assets/icons/close_cicle.svg") no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      background-color: #fff;
      cursor: pointer;
      z-index: 111;
    }

    h1, h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 30px;
      padding-bottom: 10px;
      color: white
    }

    h2 {
      padding: 20px 0;
    }

    p, li {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.5);

      &.padding {
        padding-bottom: 20px;
      }

      &.capitalise {
        text-transform: capitalize;
      }
    }

    strong {
      color: rgba(255, 255, 255, 0.5);
    }

    a {
      text-decoration: underline;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.5);
    }

    li {
      list-style-type: disc;
      list-style-position: inside;
    }

    span {
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: #D2D2D2;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 4px;
      border: none;
    }

    &::-webkit-scrollbar-track {
      background: rgba(83, 83, 83);
    }

    scrollbar-color: #D2D2D2 rgba(83, 83, 83, 0.7);
    scrollbar-width: thin;
  }
}

.search-button {
  width: 2.4rem;
  height: 2.4rem;
  background-color: white;
  mask-image: url('../../panda icons/search.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}


.ucraft-auth {
  z-index: 111;
  width: 600px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ucraft-user-account {
  width: 100%;
  height: 100%;
}

.account-is-connected {
  padding: 18px 26px;
  color: white;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 8px;
  position: absolute;
  right: 3rem;
  top: 10rem;
  font-weight: 600;
  font-size: 14px;
}

.publish-btn {
  background: #FF6900;
  border-radius: 43px;
  color: white;
  position: absolute;
  left: 50%;
  bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  border: none;
  padding: 18px 35px;
  transform: translateX(-50%);
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 6rem 3.2rem 5.2rem;
  max-width: 53rem;

  .profile-img-container {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      filter: brightness(90%);
    }
  }

  .profile-info-form-container {
    width: 100%;

    .profile-info-form-label {
      display: flex;
      flex-direction: column;
      width: 100%;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 3.2rem;
        color: #000000;
        margin-bottom: 1rem;
        margin-top: 2rem;
      }

      input {
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 3.2rem;
        color: #000000;
        border: 0.1rem solid #E9E9E9;
        border-radius: 1.2rem;
        width: 100%;
        padding: 1rem;

        :disabled {
          background-color: transparent;
        }
      }
    }
  }


  h3 {
    font-size: 16px;
  }

}

.ecommerce-iframe-container {
  width: 35vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  background-color: white;

  .icon-close-ecommerce {
    right: 2.5rem;
    filter: drop-shadow(0 0 10px black);
    position: absolute;
    top: 1.8rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 2rem;
    height: 2rem;
    background: url("../../icons/close_cicle.svg") no-repeat;
    background-size: contain;
    mask-size: contain;
    cursor: pointer;
  }

  .ecommerce-iframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .terms-outer-body {
    .terms-inner-body {
      margin: 0 0;
      width: 100%;
      height: 100%;
      transform: unset;
      .icon-close {
        top: 2rem;
        right: 2rem;
      }
    }
  }
}